import React, { memo } from "react";

import { Card } from "../../components/Card";
import styled from "../../styled-components";
import { Card as CardType } from "../../types";
import { cardsGroupedByColor } from "../../utils";

const StyledLastRoundPlayedCardsContainer = styled.div`
  grid-column: 3 / 23;
  grid-row: 7 / 27;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const StyledLastRoundCardPlaceholder = styled.div`
  height: 28px;
  width: 50px;
`;

const StyledLastRoundColumn = styled.div`
  display: flex;
  flex-direction: column;
  height: 224px;
  width: 50px;
`;

const StyledSmallCard = styled(Card)`
  height: 28px;
  width: 50px;
`;

type LastRoundProps = {
  cardsPlayed: CardType[];
};

export const LastRound: React.FC<LastRoundProps> = memo(
  ({ cardsPlayed }) => (
    <StyledLastRoundPlayedCardsContainer>
      {cardsGroupedByColor.map((cardGroup, index) => (
        <StyledLastRoundColumn key={index}>
          {cardGroup.map(card =>
            cardsPlayed.find(
              c => c.color === card.color && c.value === card.value,
            ) ? (
              <StyledSmallCard
                {...card}
                isHorizontal
                key={`lastRoundCard${card.color}${card.value}`}
              />
            ) : (
              <StyledLastRoundCardPlaceholder
                key={`lastRoundCard${card.color}${card.value}`}
              />
            ),
          )}
        </StyledLastRoundColumn>
      ))}
    </StyledLastRoundPlayedCardsContainer>
  ),
  (prevProps, props) =>
    prevProps.cardsPlayed.length === props.cardsPlayed.length,
);

LastRound.displayName = "memo(LastRound)";

import React from "react";

import { theme } from "../../theme";

export type BacksideProps = {
  className?: string;
  isHorizontal?: boolean;
};

export const Backside: React.FC<BacksideProps> = ({
  className,
  isHorizontal,
}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className={className}
    height={isHorizontal ? "56" : "100"}
    width={isHorizontal ? "100" : "56"}
    viewBox={isHorizontal ? "0 0 100 56" : "0 0 56 100"}
  >
    <g transform={isHorizontal ? "rotate(90) translate(0 -100)" : ""}>
      <path fill={theme.colors.white} d="M12 12h32v76H12z" />
      <path
        d="M12 12v5l3 3v-5M12 22v5l3 3v-5M12 32v5l3 3v-5M12 42v5l3 3v-5M12 52v5l3 3v-5M12 62v5l3 3v-5M12 72v5l3 3v-5M12 82v5l3 3v-5M12 92v5l3 3v-5M15 10v5l3 3v-5M15 20v5l3 3v-5M15 30v5l3 3v-5M15 40v5l3 3v-5M15 50v5l3 3v-5M15 60v5l3 3v-5M15 70v5l3 3v-5M15 80v5l3 3v-5M15 90v5l3 3v-5M18 8v5l3 3v-5M18 18v5l3 3v-5M18 28v5l3 3v-5M18 38v5l3 3v-5M18 48v5l3 3v-5M18 58v5l3 3v-5M18 68v5l3 3v-5M18 78v5l3 3v-5M18 88v5l3 3v-5M21 6v5l3 3V9M21 16v5l3 3v-5M21 26v5l3 3v-5M21 36v5l3 3v-5M21 46v5l3 3v-5M21 56v5l3 3v-5M21 66v5l3 3v-5M21 76v5l3 3v-5M21 86v5l3 3v-5M24 14v5l3 3v-5M24 24v5l3 3v-5M24 34v5l3 3v-5M24 44v5l3 3v-5M24 54v5l3 3v-5M24 64v5l3 3v-5M24 74v5l3 3v-5M24 84v5l3 3v-5M24 94v5l3 3v-5M27 12v5l3 3v-5M27 22v5l3 3v-5M27 32v5l3 3v-5M27 42v5l3 3v-5M27 52v5l3 3v-5M27 62v5l3 3v-5M27 72v5l3 3v-5M27 82v5l3 3v-5M27 92v5l3 3v-5M30 10v5l3 3v-5M30 20v5l3 3v-5M30 30v5l3 3v-5M30 40v5l3 3v-5M30 50v5l3 3v-5M30 60v5l3 3v-5M30 70v5l3 3v-5M30 80v5l3 3v-5M30 90v5l3 3v-5M33 8v5l3 3v-5M33 18v5l3 3v-5M33 28v5l3 3v-5M33 38v5l3 3v-5M33 48v5l3 3v-5M33 58v5l3 3v-5M33 68v5l3 3v-5M33 78v5l3 3v-5M33 88v5l3 3v-5M36 6v5l3 3V9M36 16v5l3 3v-5M36 26v5l3 3v-5M36 36v5l3 3v-5M36 46v5l3 3v-5M36 56v5l3 3v-5M36 66v5l3 3v-5M36 76v5l3 3v-5M36 86v5l3 3v-5M39 14v5l3 3v-5M39 24v5l3 3v-5M39 34v5l3 3v-5M39 44v5l3 3v-5M39 54v5l3 3v-5M39 64v5l3 3v-5M39 74v5l3 3v-5M39 84v5l3 3v-5M39 94v5l3 3v-5M42 12v5l3 3v-5M42 22v5l3 3v-5M42 32v5l3 3v-5M42 42v5l3 3v-5M42 52v5l3 3v-5M42 62v5l3 3v-5M42 72v5l3 3v-5M42 82v5l3 3v-5M42 92v5l3 3v-5"
        fill={theme.colors.blue}
      />
      <rect
        strokeWidth="12"
        x="6"
        y="6"
        rx="1"
        ry="1"
        height="88"
        width="44"
        fill="none"
        stroke={theme.colors.white}
      />
      <rect
        strokeWidth="2"
        x="1"
        y="1"
        width="54"
        height="98"
        rx="5"
        ry="5"
        fill="none"
        stroke={theme.colors.black}
      />
      <path
        strokeWidth="2"
        fill="none"
        stroke={theme.colors.black}
        d="M11 11h34v78H11z"
      />
      <circle
        strokeWidth="2"
        cx="28"
        cy="50"
        r="10"
        fill={theme.colors.white}
        stroke={theme.colors.black}
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(.8 0 0 2 -16.4 -72)"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(.8 0 0 -2 -16.4 172)"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(-.8 0 0 2 72.4 -72)"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(-.8 0 0 -2 72.4 172)"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(0 .8 -2 0 128 -16.4)"
      />
      <path
        fill={theme.colors.yellow}
        stroke={theme.colors.black}
        strokeWidth=".5"
        d="M25 55c0 1 1 2.3 2 2.3 3 0 3-2.3 3-3.3s0-2-1-4-1-3-1-4 0-2.7 1-2.7 2 .7 2 1.7c0-1-1-2.3-2-2.3-3 0-3 2.3-3 3.3s0 2 1 4 1 3 1 4 0 2.7-1 2.7-2-.7-2-1.7"
        transform="matrix(0 -.8 -2 0 128 116.4)"
      />
    </g>
  </svg>
);

import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { createContext, useContext, useEffect, useReducer } from "react";

import { Result, User } from "../types";
import { createUser } from "../utils";
import { AuthContext } from "./AuthContext";

type UserState = {
  data: User | null;
  isLoading: boolean;
  ref: firebase.firestore.DocumentReference | null;
};

type UserAction =
  | {
      type: "UPDATE_USER";
      payload: {
        data: User | null;
        ref: firebase.firestore.DocumentReference | null;
      };
    }
  | { type: "UPDATE_USER_LOADING"; payload: boolean };

type UserContextType = [UserState, React.Dispatch<UserAction>];

const initialState: UserState = {
  data: null,
  isLoading: true,
  ref: null,
};

const initialValue: UserContextType = [initialState, () => null];

export const UserContext = createContext(initialValue);

const reducer = (state: UserState, action: UserAction) => {
  let newState: UserState;
  switch (action.type) {
    case "UPDATE_USER":
      newState = {
        ...state,
        ...action.payload,
        isLoading: false,
      };
      break;
    case "UPDATE_USER_LOADING":
      newState = {
        ...state,
        isLoading: action.payload,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const UserContextProvider: React.FC = ({ children }) => {
  const [auth] = useContext(AuthContext);
  const [globalState, dispatch] = useReducer(reducer, initialState);

  const uid = auth.doc ? auth.doc.uid : null;
  useEffect(() => {
    if (!uid) {
      dispatch({ type: "UPDATE_USER", payload: { data: null, ref: null } });
      return;
    }
    dispatch({ type: "UPDATE_USER_LOADING", payload: true });
    const ref = firebase
      .firestore()
      .collection("users")
      .doc(uid);
    return ref.onSnapshot(doc => {
      if (!doc.exists) {
        ref.set(createUser());
        return;
      }
      dispatch({
        payload: { data: doc.data() as User, ref: doc.ref },
        type: "UPDATE_USER",
      });
    });
  }, [uid]);

  useEffect(
    () =>
      firebase
        .firestore()
        .collection("results")
        .where("uid", "==", uid)
        .onSnapshot(querySnapshot => {
          const [resultDoc] = querySnapshot.docs;
          if (resultDoc && globalState.data && globalState.ref) {
            const result = resultDoc.data() as Result;
            const userUpdate: Pick<
              User,
              "gamePlacements" | "gamesPlayed" | "runningScore"
            > = {
              gamePlacements: globalState.data.gamePlacements,
              gamesPlayed: globalState.data.gamesPlayed,
              runningScore: globalState.data.runningScore,
            };
            userUpdate.gamePlacements[result.place] += 1;
            userUpdate.gamesPlayed += 1;
            userUpdate.runningScore += result.score;
            globalState.ref.update(userUpdate);
            resultDoc.ref.delete();
          }
        }),
    [uid, globalState.data, globalState.ref],
  );

  return (
    <UserContext.Provider value={[globalState, dispatch]}>
      {children}
    </UserContext.Provider>
  );
};

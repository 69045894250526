import React, { useState } from "react";

import styled from "../../styled-components";

const StyledWrapper = styled.div`
  display: flex;
  > * {
    border: ${props => props.theme.borders._2} solid
      ${props => props.theme.colors.black};
  }
  > * + * {
    border-left: none;
  }
`;

const StyledButton = styled.button`
  font-size: ${props => props.theme.fontSizes._30};
  min-width: ${props => props.theme.spacings._48};
  background-color: ${props => props.theme.colors.gray[800]};
  cursor: pointer;
`;

const StyledValue = styled.span`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

type CounterProps = {
  getLabelFromValue?: (value: number) => string;
  min: number;
  max: number;
  onChange?: (value: number) => void;
  reverse?: boolean;
  step: number;
  value: number;
};

export const Counter: React.FC<CounterProps> = ({
  getLabelFromValue = v => v.toString(),
  max,
  min,
  onChange,
  reverse = false,
  step,
  value,
}) => {
  const boundedValue = Math.min(max, Math.max(min, value));
  const [currentValue, setCurrentValue] = useState(boundedValue);
  const decrement = () => {
    if (currentValue <= min) return;
    setCurrentValue(v => {
      const newValue = Math.max(min, v - step);
      if (onChange) onChange(newValue);
      return newValue;
    });
  };
  const increment = () => {
    if (currentValue >= max) return;
    setCurrentValue(v => {
      const newValue = Math.min(max, v + step);
      if (onChange) onChange(newValue);
      return newValue;
    });
  };
  const isAtMax = currentValue >= max;
  const isAtMin = currentValue <= min;
  return (
    <StyledWrapper>
      <StyledButton
        disabled={reverse ? isAtMax : isAtMin}
        onClick={reverse ? increment : decrement}
        type="button"
      >
        -
      </StyledButton>
      <StyledValue>{getLabelFromValue(currentValue)}</StyledValue>
      <StyledButton
        disabled={reverse ? isAtMin : isAtMax}
        onClick={reverse ? decrement : increment}
        type="button"
      >
        +
      </StyledButton>
    </StyledWrapper>
  );
};

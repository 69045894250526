import { navigate, RouteComponentProps } from "@reach/router";
import React, { useContext } from "react";

import { Container } from "../../components/Container";
import { GameLinks } from "../../components/GameLinks";
import { StyledHeading } from "../../components/StyledHeading";
import { MultiPlayerGamesContext, UserContext } from "../../context";
import { useRedirectOnNetworkStatusChange } from "../../hooks";
import styled from "../../styled-components";
import { NetworkStatus, Route } from "../../types";

const StyledTable = styled.table`
  width: 100%;
`;

type StyledTableRowProps = {
  isActive: boolean;
};

const StyledTableRow = styled.tr<StyledTableRowProps>`
  background-color: ${props =>
    props.isActive ? props.theme.colors.gray[700] : "none"};
`;

const StyledTableColumn = styled.td`
  text-align: right;
`;

const rounds = [
  "jeder Stich",
  "jedes Herz",
  "jeder Unter",
  "jeder Ober",
  "Erdbeerschorsch",
  "letzter Stich",
  "Unter legen",
];

type Props = { gameId?: string };

export const GameScore: React.FC<RouteComponentProps<Props>> = ({ gameId }) => {
  useRedirectOnNetworkStatusChange(
    NetworkStatus.OFFLINE,
    Route.MULTI_PLAYER,
    Boolean(gameId),
  );
  const [user] = useContext(UserContext);
  const { isLoading, games } = useContext(MultiPlayerGamesContext);
  const game = gameId ? games[gameId] : user.data ? user.data.activeGame : null;
  if (!gameId && user.data && !game) {
    navigate(Route.DASHBOARD);
    return null;
  }
  if (gameId && !isLoading && !game) {
    navigate(Route.DASHBOARD);
    return null;
  }
  if (!game) {
    return null;
  }
  return (
    <Container>
      <GameLinks game={game} gameId={gameId} />
      <StyledHeading data-testid="next-click">Spielstand</StyledHeading>
      <StyledTable>
        <thead>
          <tr>
            <th />
            {game.players.map((player, index) => (
              <StyledTableColumn as="th" key={`${player.name}-${index}`}>
                {player.name.substring(0, 2)}
              </StyledTableColumn>
            ))}
          </tr>
        </thead>
        <tbody>
          {rounds.map((round, index) => (
            <StyledTableRow key={round} isActive={index === game.round}>
              <td>{round}</td>
              {game.players.map((player, playerIndex) => (
                <StyledTableColumn key={`${player.name}-${playerIndex}`}>
                  {player.scorePerRound[index]}
                </StyledTableColumn>
              ))}
            </StyledTableRow>
          ))}
        </tbody>
      </StyledTable>
    </Container>
  );
};

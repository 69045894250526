import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { memo, useRef } from "react";

import styled from "../../styled-components";
import { Game } from "../../types";
import {
  getSortValueForCard,
  isCardLegalInLastRound,
  playCard,
} from "../../utils";
import { PlayerCardsProps, StyledCard } from "./shared";

const StyledBottomPlayerName = styled.span`
  grid-column: 1 / 25;
  grid-row: 27 / 28;
  text-align: center;
  margin: ${props => props.theme.spacings._16} 0;
`;

export const BottomPlayerName = memo(StyledBottomPlayerName);

export const BottomPlayerCards: React.FC<
  PlayerCardsProps & {
    game: Game;
    gameId?: string;
    highlightLegalCards: boolean;
    isActive: boolean;
    sortCards: boolean;
    userRef: firebase.firestore.DocumentReference | null;
  }
> = memo(
  ({
    game,
    gameId,
    hand,
    highlightLegalCards,
    isActive,
    sortCards,
    userRef,
  }) => {
    const updateRunningRef = useRef(false);
    const hasLegalCards =
      hand.filter(
        card =>
          game.firstCardPlayed && card.color === game.firstCardPlayed.color,
      ).length > 0;
    const sortedHand = sortCards
      ? hand.sort(
          (card1, card2) =>
            getSortValueForCard(card1) - getSortValueForCard(card2),
        )
      : hand;
    return (
      <>
        {sortedHand.map((card, cardIndex) => {
          const isLegalInNormalRounds = game.firstCardPlayed
            ? !hasLegalCards || game.firstCardPlayed.color === card.color
            : true;
          const isLegalInLastRound = isCardLegalInLastRound(
            card,
            game.cardsPlayedInLastRound,
          );
          const isLegal =
            game.round === 6 ? isLegalInLastRound : isLegalInNormalRounds;
          const onClick =
            isActive && isLegal
              ? () => {
                  if (!gameId && !userRef) return;
                  if (!updateRunningRef.current) {
                    updateRunningRef.current = true;
                    const gameUpdate = playCard(
                      game,
                      card,
                      !game.firstCardPlayed,
                    );
                    if (gameId) {
                      firebase
                        .firestore()
                        .collection("games")
                        .doc(gameId)
                        .update(gameUpdate)
                        .then(() => {
                          updateRunningRef.current = false;
                        })
                        .catch(() => {
                          updateRunningRef.current = false;
                        });
                    } else if (userRef) {
                      userRef
                        .update({ activeGame: gameUpdate })
                        .then(() => {
                          updateRunningRef.current = false;
                        })
                        .catch(() => {
                          updateRunningRef.current = false;
                        });
                    }
                  }
                }
              : undefined;
          return (
            <StyledCard
              key={`bottomPlayerCard${cardIndex}`}
              onClick={onClick}
              isIllegal={highlightLegalCards && isActive && !isLegal}
              {...(isActive && isLegal ? { testId: "next-click" } : {})}
              {...card}
            />
          );
        })}
      </>
    );
  },
  (prevProps, props) =>
    prevProps.gameId === props.gameId &&
    prevProps.hand.length === props.hand.length &&
    prevProps.isActive === props.isActive &&
    prevProps.sortCards === props.sortCards &&
    prevProps.highlightLegalCards === props.highlightLegalCards &&
    !(
      prevProps.game.firstCardPlayed !== null &&
      props.game.firstCardPlayed === null
    ),
);

BottomPlayerCards.displayName = "memo(BottomPlayerCards)";

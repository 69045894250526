import { navigate } from "@reach/router";
import React, { useContext } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Counter } from "../../components/Counter";
import { Divider } from "../../components/Divider";
import { Stack } from "../../components/Stack";
import {
  StyledHeading,
  StyledSubHeading,
} from "../../components/StyledHeading";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { Switch } from "../../components/Switch";
import { AuthContext, UserContext } from "../../context";
import { Route, Settings as SettingsType, User } from "../../types";

export const Settings: React.FC = () => {
  const [firebaseUser] = useContext(AuthContext);
  const [user] = useContext(UserContext);
  if (!firebaseUser.doc || !user.data || !user.ref) return null;
  const { data: userData, ref: userRef } = user;
  const { displayName } = firebaseUser.doc;
  const getUserUpdate = (settings: SettingsType): Partial<User> =>
    userData.activeGame
      ? { activeGame: { ...userData.activeGame, settings }, settings }
      : { settings };
  return (
    <Container>
      <StyledLinkButton
        onClick={() => {
          window.history.back();
        }}
      >
        zurück
      </StyledLinkButton>
      <Divider />
      <Stack scale="_32">
        <StyledHeading>Einstellungen</StyledHeading>
        <Stack scale="_24">
          <StyledSubHeading>Spieleinstellungen</StyledSubHeading>
          <Stack scale="_8">
            <p>Karten sortieren:</p>
            <div>
              <Switch
                isEnabled={userData.settings.sortCards}
                onChange={sortCards => {
                  const settings: SettingsType = {
                    ...userData.settings,
                    sortCards,
                  };
                  userRef.update(getUserUpdate(settings));
                }}
              />
            </div>
          </Stack>
          <Stack scale="_8">
            <p>Spielbare Karten hervorheben:</p>
            <div>
              <Switch
                isEnabled={userData.settings.highlightLegalCards}
                onChange={highlightLegalCards => {
                  const settings: SettingsType = {
                    ...userData.settings,
                    highlightLegalCards,
                  };
                  userRef.update(getUserUpdate(settings));
                }}
              />
            </div>
          </Stack>
          <Stack scale="_8">
            <p>Runde vorzeitig beenden, wenn alle Punkte vergeben sind:</p>
            <div>
              <Switch
                isEnabled={userData.settings.endRoundEarly}
                onChange={endRoundEarly => {
                  const settings: SettingsType = {
                    ...userData.settings,
                    endRoundEarly,
                  };
                  userRef.update(getUserUpdate(settings));
                }}
              />
            </div>
          </Stack>
          <Stack scale="_8">
            <p>Geschwindigkeit der anderen Spieler:</p>
            <Counter
              getLabelFromValue={value => {
                if (value <= 250) return "Super schnell";
                if (value <= 500) return "Schnell";
                if (value <= 750) return "Normal";
                if (value <= 1000) return "Langsam";
                return "Super Langsam";
              }}
              min={250}
              max={1250}
              onChange={gameSpeed => {
                const settings: SettingsType = {
                  ...userData.settings,
                  gameSpeed,
                };
                userRef.update(getUserUpdate(settings));
              }}
              reverse
              step={250}
              value={userData.settings.gameSpeed}
            />
          </Stack>
          <p>
            Diese Einstellungen gelten nur für Spiele, in denen du alleine
            spielst, und für Spiele mit mehreren Spieler, die du erstellt hast.
          </p>
        </Stack>
        <Divider />
        <Stack scale="_24">
          <StyledSubHeading>Account</StyledSubHeading>
          <p>
            Dein aktueller Benutzername ist <strong>{displayName}</strong>.
          </p>
          <ButtonWrapper>
            <Button onClick={() => navigate(Route.SET_USERNAME)}>
              Benutzername ändern
            </Button>
          </ButtonWrapper>
          <ButtonWrapper>
            <Button onClick={() => navigate(Route.DELETE_ACCOUNT)}>
              Account löschen
            </Button>
          </ButtonWrapper>
        </Stack>
        <Divider />
        <Stack scale="_24">
          <StyledSubHeading>Über die App</StyledSubHeading>
          <p>Version 0.4.0</p>
          <p>
            Erstellt von Thomas Heyenbrock, &copy; {new Date().getFullYear()}
          </p>
        </Stack>
      </Stack>
    </Container>
  );
};

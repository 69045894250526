import { navigate, RouteComponentProps } from "@reach/router";
import React, { useContext, useRef } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Input } from "../../components/Input";
import { Stack } from "../../components/Stack";
import { StyledErrorMessage } from "../../components/StyledErrorMessage";
import { StyledHeading } from "../../components/StyledHeading";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { AuthContext } from "../../context";
import { useForm } from "../../hooks";
import { Route } from "../../types";
import { mapErrorToMessage } from "../../utils";

export const SetUsername: React.FC<RouteComponentProps> = ({ path }) => {
  const [firebaseUser, dispatch] = useContext(AuthContext);
  const form = useRef<HTMLFormElement>(null);
  const { error, isSubmitting, submit } = useForm(
    form,
    ["name"],
    async (formValues, setError) => {
      if (formValues.name === "") return setError("Bitte Namen eingeben");
      setError(null);
      try {
        await firebaseUser.doc!.updateProfile({ displayName: formValues.name });
        dispatch({ type: "UPDATE_FIREBASE_USER" });
        navigate(Route.DASHBOARD);
        return false;
      } catch (error) {
        setError(mapErrorToMessage(error));
      }
    },
  );
  const isFirstVisit = path === Route.FIRST_LOGIN;
  return (
    <Container>
      {isFirstVisit ? (
        <>
          <StyledHeading>Servus!</StyledHeading>
          <p>
            Es sieht aus als wärst du zum ersten Mal hier. Du brauchst noch
            einen Namen zum Karalaxen, damit die anderen Spieler sehen wer du
            bist.
          </p>
        </>
      ) : (
        <>
          <StyledLinkButton
            onClick={() => {
              window.history.back();
            }}
          >
            zurück
          </StyledLinkButton>
          <Divider />
          <p>
            Hier kannst du deinen Benutzernamen ändern. Er wird in allen neu
            gestarteten Spielen verwendet werden.
          </p>
        </>
      )}
      <form onSubmit={submit} ref={form}>
        <Stack scale="_12">
          <label htmlFor="name">Name:</label>
          <Input id="name" type="text" />
          <ButtonWrapper>
            <Button isLoading={isSubmitting} priority="primary" type="submit">
              {isFirstVisit ? "Auf zum Kartln" : "Name ändern"}
            </Button>
          </ButtonWrapper>
          {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </Stack>
      </form>
    </Container>
  );
};

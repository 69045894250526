import * as firebase from "firebase/app";
import "firebase/firestore";
import React from "react";
import ReactDOM from "react-dom";
import "sanitize.css";

import { GlobalStyles } from "./components/GlobalStyles";
import { AuthContextProvider, NetworkContextProvider } from "./context";
import { App } from "./pages/App";
import * as serviceWorker from "./serviceWorker";
import { ThemeProvider } from "./styled-components";
import { theme } from "./theme";

firebase.initializeApp({
  apiKey: "AIzaSyAPEt4oll_eI_fz8BkW2QdspZ6h1tBK2a8",
  authDomain: "karalaxen-aafda.firebaseapp.com",
  databaseURL: "https://karalaxen-aafda.firebaseio.com",
  messagingSenderId: "112125804499",
  projectId: "karalaxen-aafda",
  storageBucket: "karalaxen-aafda.appspot.com",
});

firebase
  .firestore()
  .enablePersistence({ experimentalTabSynchronization: true });

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <AuthContextProvider>
      <NetworkContextProvider>
        <GlobalStyles />
        <App />
      </NetworkContextProvider>
    </AuthContextProvider>
  </ThemeProvider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();

type CardColor = "eichel" | "gras" | "herz" | "schellen";

type CardValue = "7" | "8" | "9" | "10" | "U" | "O" | "K" | "A";

export type Card = {
  color: CardColor;
  value: CardValue;
};

export type Player = {
  cardPlayed: Card | null;
  finishedInPlace: number | null;
  hand: Card[];
  indicesOfTricks: number[];
  name: string;
  scorePerRound: number[];
  tricks: Card[];
  uid: string | null;
};

export type Settings = {
  endRoundEarly: boolean;
  gameSpeed: number;
  highlightLegalCards: boolean;
  sortCards: boolean;
};

export type Game = {
  activePlayerIndex: number;
  authorizedUsers: string[];
  cardsPlayedInLastRound: Card[];
  createdBy: string;
  firstCardPlayed: Card | null;
  initialPlayerIndex: number;
  players: Player[];
  round: number;
  settings: Settings;
  timestamp: object;
};

export type User = {
  activeGame: Game | null;
  gamePlacements: { [key: number]: number };
  gamesPlayed: number;
  runningScore: number;
  settings: Settings;
};

export enum Route {
  DASHBOARD = "/dashboard",
  DELETE_ACCOUNT = "/settings/delete-account",
  FIRST_LOGIN = "/first-login",
  GAME = "/game",
  GAME_SCORE = "/game/score",
  MULTI_PLAYER = "/multiplayer",
  OPENINGS = "/multiplayer/openings",
  SET_USERNAME = "/settings/set-username",
  SETTINGS = "/settings",
  START_GAME = "/game/start",
  STATISTICS = "/statistics",
}

export type OpeningPlayer = {
  name: string;
  uid: string;
};

export type Opening = {
  applicants: OpeningPlayer[];
  createdAt: firebase.firestore.Timestamp;
  name: string;
  players: OpeningPlayer[];
  uid: string;
};

export enum NetworkStatus {
  ONLINE = "online",
  OFFLINE = "offline",
}

export type Result = {
  place: number;
  score: number;
  uid: string;
};

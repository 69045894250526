import { Game } from "../types";

export const getActivePlayerIndexInLastRound = (game: Game) => {
  if (game.cardsPlayedInLastRound.length === 31) {
    return game.activePlayerIndex;
  }
  let activePlayerIndex = (game.activePlayerIndex + 1) % 4;
  if (game.players[activePlayerIndex].hand.length === 0) {
    activePlayerIndex = (activePlayerIndex + 1) % 4;
  }
  if (game.players[activePlayerIndex].hand.length === 0) {
    activePlayerIndex = (activePlayerIndex + 1) % 4;
  }
  if (game.players[activePlayerIndex].hand.length === 0) {
    activePlayerIndex = (activePlayerIndex + 1) % 4;
  }
  return activePlayerIndex;
};

import React, { useState } from "react";
import styled from "../../styled-components";

const StyledButton = styled.button`
  border: none;
  background: none;
  margin: 0;
  padding: 0;
`;

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
  border: ${props => props.theme.borders._2} solid
    ${props => props.theme.colors.black};
`;

const StyledTextWrapper = styled.div`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
`;

const StyledText = styled.span`
  padding: ${props => props.theme.spacings._8};
  width: ${props => props.theme.spacings._64};
  text-align: center;
`;

type StyledSwitchProps = {
  enabled: boolean;
};

const StyledSwitch = styled.div<StyledSwitchProps>`
  grid-column: 1 / 2;
  grid-row: 1 / 2;
  background-color: ${props => props.theme.colors.gray[800]};
  margin-top: ${props => props.theme.spacings._4};
  margin-bottom: ${props => props.theme.spacings._4};
  margin-left: ${props => (props.enabled ? "auto" : props.theme.spacings._4)};
  margin-right: ${props => (props.enabled ? props.theme.spacings._4 : "auto")};
  border: ${props => props.theme.borders._2} solid
    ${props => props.theme.colors.black};
  width: calc(50% - 8px);
`;

type SwitchProps = {
  isEnabled: boolean;
  onChange?: (value: boolean) => void;
};

export const Switch: React.FC<SwitchProps> = ({ isEnabled, onChange }) => {
  const [enabled, setEnabled] = useState(isEnabled);
  return (
    <StyledButton
      type="button"
      onClick={() => {
        setEnabled(v => {
          const newValue = !v;
          if (onChange) onChange(newValue);
          return newValue;
        });
      }}
    >
      <StyledWrapper>
        <StyledTextWrapper>
          <StyledText>ja</StyledText>
          <StyledText>nein</StyledText>
        </StyledTextWrapper>
        <StyledSwitch aria-hidden="true" enabled={enabled} />
      </StyledWrapper>
    </StyledButton>
  );
};

import { useRef, useState } from "react";

export const useToggle = (initialState: boolean) => {
  const [currentState, setCurrentState] = useState(initialState);
  const { current: toggle } = useRef(() => setCurrentState(isOpen => !isOpen));
  const { current: show } = useRef(() => setCurrentState(true));
  const { current: hide } = useRef(() => setCurrentState(false));
  const toggleState: [boolean, () => void, () => void, () => void] = [
    currentState,
    toggle,
    show,
    hide,
  ];
  return toggleState;
};

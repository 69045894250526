import { createGlobalStyle } from "../../styled-components";

export const GlobalStyles = createGlobalStyle`
  body {
    color:${props => props.theme.colors.black};
    font-size: ${props => props.theme.fontSizes._20};
    font-family: 'EB Garamond', serif;
    overscroll-behavior-y: contain;
  }

  h1, h2, h3, h4, h5, h6, p {
    margin: 0;
  }

  button, input {
    font-family: 'EB Garamond', serif;
    font-size: ${props => props.theme.fontSizes._20};
  }
`;

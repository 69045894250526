import React from "react";

import { theme } from "../../theme";

type EichelProps = {
  showPulse?: boolean;
};

export const Eichel: React.FC<EichelProps> = ({ showPulse = false }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" width="66" height="121">
    <g style={{ transformOrigin: "center" }} stroke={theme.colors.black}>
      {showPulse && (
        <>
          <animateTransform
            id="svg-eichel-pulse-1"
            attributeName="transform"
            type="scale"
            from="1"
            to="0.8"
            dur="0.25s"
            begin="0s;svg-eichel-pulse-1.end+0.75s"
          />
          <animateTransform
            id="svg-eichel-pulse-2"
            attributeName="transform"
            type="scale"
            from="0.8"
            to="1"
            dur="0.25s"
            begin="0.25s;svg-eichel-pulse-2.end+0.75s"
          />
        </>
      )}
      <path fill={theme.colors.yellow} d="M13 21Q13 1 33 1v51H13z" />
      <path fill={theme.colors.red[500]} d="M33 1q20 0 20 20v31H33z" />
      <path fill={theme.colors.blue} d="M13 52h20v10H13z" />
      <path
        fill={theme.colors.green}
        d="M37 96q0 23-21 23l8-9q1-1 3-2t2-6v-6"
      />
      <path
        fill="none"
        d="M27 108q4.2 0 4.2 2.5 0 3.5-2.2 5.07M35 96q0 11-4 15M33 96q0 6-1.5 10"
      />
      <path fill="none" d="M20 117l6-6q2-2 3 0 .5 1-1 2" />
      <path fill={theme.colors.black} d="M33 52v10h20V52z" />
      <path
        fill={theme.colors.green}
        d="M13 62Q1 62 1 74q0 22 32 22 31 0 31-22 0-12-10-12z"
      />
      <path
        fill={theme.colors.black}
        d="M33 66.5q-4.5 0-4.5 4.5 0 6 4.5 16 4.5-10 4.5-16 0-4.5-4.5-4.5M21 89q-11-9-11-15 0-7 5-7 2 0 2 4 0 1-.5 2t-.5 2q0 5 5 14M45 89q11-9 11-15 0-7-5-7-2 0-2 4 0 1 .5 2t.5 2q0 5-5 14"
      />
      <path
        fill="none"
        d="M33 62v34M35.86 1v95M38.7 1.8V96M41.6 2.7V95M44.4 3v92M47.3 5.4v89.2M50.1 8.8v84.9M53 62v30.6M55.8 62.3V91M58.7 63v25.6M61.6 65v19.7"
      />
    </g>
  </svg>
);

import styled from "../../styled-components";

export const Input = styled.input`
  border: ${props => props.theme.borders._2} solid
    ${props => props.theme.colors.black};
  padding: ${props => props.theme.spacings._8};

  &:active,
  &:focus {
    box-shadow: 0 0 0
      ${props => `${props.theme.borders._4} ${props.theme.colors.gray[500]}`};
  }
`;

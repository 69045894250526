import { navigate } from "@reach/router";
import { useContext, useEffect } from "react";

import { NetworkContext } from "../context";
import { NetworkStatus, Route } from "../types";

export const useRedirectOnNetworkStatusChange = (
  networkStatus: NetworkStatus,
  redirectTo: Route,
  conditionForRedirect?: boolean,
) => {
  const currentNetworkStatus = useContext(NetworkContext);
  const condition =
    typeof conditionForRedirect === "boolean" ? conditionForRedirect : true;
  useEffect(() => {
    if (currentNetworkStatus === networkStatus && condition) {
      navigate(redirectTo);
    }
  }, [currentNetworkStatus, networkStatus, redirectTo, condition]);
};

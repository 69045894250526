import { Card } from "../types";

export const getScoreForPlayer = (
  tricks: Card[],
  indicesOfTricks: number[],
  finishedInPlace: number,
  round: number,
) => {
  switch (round) {
    case 0:
      return Math.floor(tricks.length / 4) * -1;
    case 1:
      return tricks.filter(card => card.color === "herz").length * -1;
    case 2:
      return tricks.filter(card => card.value === "U").length * -2;
    case 3:
      return tricks.filter(card => card.value === "O").length * -2;
    case 4:
      return (
        tricks.filter(card => card.color === "herz" && card.value === "K")
          .length * -8
      );
    case 5:
      return indicesOfTricks.filter(index => index === 7).length * -8;
    case 6:
      return (3 - finishedInPlace) * 8;
    default:
      return 0;
  }
};

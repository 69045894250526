import { navigate } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Loading } from "../../components/Loading";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { AuthContext, OpeningsContext } from "../../context";
import { useRedirectOnNetworkStatusChange } from "../../hooks";
import { NetworkStatus, Opening, Route } from "../../types";

export const Openings: React.FC = () => {
  useRedirectOnNetworkStatusChange(NetworkStatus.OFFLINE, Route.MULTI_PLAYER);
  const [user] = useContext(AuthContext);
  const { isLoading, openings } = useContext(OpeningsContext);
  if (isLoading || user.isLoading || !user.doc) {
    return <Loading />;
  }
  const { displayName, uid } = user.doc;
  const filteredOpenings = openings.filter(
    opening =>
      opening.uid !== uid &&
      !opening.players.find(player => player.uid === uid) &&
      !opening.applicants.find(applicant => applicant.uid === uid),
  );
  return (
    <Container>
      <StyledLinkButton
        onClick={() => {
          window.history.back();
        }}
      >
        zurück
      </StyledLinkButton>
      <Divider />
      {filteredOpenings.length === 0 && (
        <p>Im Moment will noch kein Spieler ein Spiel eröffnen.</p>
      )}
      {filteredOpenings.map((opening, index) => {
        const isAlreadyApplying = Boolean(
          opening.applicants.find(applicant => applicant.uid === uid),
        );
        const isAlreadyPlaying = Boolean(
          opening.players.find(player => player.uid === uid),
        );
        return (
          <Fragment key={opening.uid}>
            {index > 0 && <Divider />}
            <p>
              Erstellt von <strong>{opening.name}</strong> am{" "}
              {opening.createdAt.toDate().toLocaleString()} Uhr
            </p>
            {!isAlreadyApplying && !isAlreadyPlaying && (
              <ButtonWrapper>
                <Button
                  onClick={async () => {
                    const openingUpdate: Partial<Opening> = {
                      applicants: [
                        ...opening.applicants,
                        { name: displayName || "", uid },
                      ],
                    };
                    await firebase
                      .firestore()
                      .collection("openings")
                      .doc(opening.uid)
                      .update(openingUpdate);
                    navigate(`${Route.START_GAME}/${opening.uid}`);
                  }}
                >
                  mitspielen
                </Button>
              </ButtonWrapper>
            )}
            {isAlreadyApplying && (
              <>
                <p>Du willst hier bereits mitspielen.</p>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      navigate(`${Route.START_GAME}/${opening.uid}`);
                    }}
                  >
                    zur Spieleröffnung
                  </Button>
                </ButtonWrapper>
              </>
            )}
            {isAlreadyPlaying && (
              <>
                <p>Du darfst hier bereits mitspielen.</p>
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      navigate(`${Route.START_GAME}/${opening.uid}`);
                    }}
                  >
                    zur Spieleröffnung
                  </Button>
                </ButtonWrapper>
              </>
            )}
          </Fragment>
        );
      })}
    </Container>
  );
};

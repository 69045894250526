import { navigate } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { useContext, useState } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Loading } from "../../components/Loading";
import { Stack } from "../../components/Stack";
import { StyledHeading } from "../../components/StyledHeading";
import { AuthContext, UserContext } from "../../context";
import { Route } from "../../types";
import { createGame } from "../../utils";

export const Dashboard: React.FC = () => {
  const [firebaseUser] = useContext(AuthContext);
  const [user] = useContext(UserContext);
  const [showSinglePlayerButtons, setShowSinglePlayerButtons] = useState(false);
  const [isStartingSinglePlayerGame, setIsStartingSinglePlayerGame] = useState(
    false,
  );
  if (user.isLoading || !user.data || !firebaseUser.doc) {
    return <Loading />;
  }

  const { displayName, uid } = firebaseUser.doc;
  const userData = user.data;

  const startNewGame = () => {
    setIsStartingSinglePlayerGame(true);
    return firebase
      .firestore()
      .collection("users")
      .doc(uid)
      .update({
        activeGame: createGame(
          { name: displayName || "", uid },
          [],
          userData.settings,
        ),
      });
  };

  return (
    <Container>
      <Stack scale="_32">
        <StyledHeading>Servus {displayName || ""}!</StyledHeading>
        <p>
          Du kannst entweder alleine gegen intelligente Gegner oder zusammen mit
          anderen Spielern karteln!
        </p>
        <Stack>
          <ButtonWrapper>
            <Button
              isLoading={isStartingSinglePlayerGame}
              onClick={async () => {
                if (userData.activeGame) {
                  setShowSinglePlayerButtons(value => !value);
                } else {
                  await startNewGame();
                  navigate(Route.GAME);
                }
              }}
              priority="primary"
            >
              Alleine spielen
            </Button>
          </ButtonWrapper>
          {showSinglePlayerButtons && (
            <>
              <p>Du hast bereits ein Spiel am laufen.</p>
              <ButtonWrapper>
                <Button
                  onClick={() => {
                    navigate(Route.GAME);
                  }}
                >
                  Spiel fortsetzen
                </Button>
                <Button
                  onClick={async () => {
                    await startNewGame();
                    navigate(Route.GAME);
                  }}
                >
                  neues Spiel starten
                </Button>
              </ButtonWrapper>
            </>
          )}
        </Stack>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(Route.MULTI_PLAYER);
            }}
            priority="primary"
          >
            Mit anderen Spielern spielen
          </Button>
        </ButtonWrapper>
      </Stack>
    </Container>
  );
};

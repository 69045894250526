import styled from "../../styled-components";
import { theme } from "../../theme";

type StackProps = {
  scale?: keyof typeof theme.spacings;
};

export const Stack = styled.div<StackProps>`
  display: flex;
  flex-direction: column;
  > * + * {
    margin-top: ${props => props.theme.spacings[props.scale || "_16"]};
  }
`;

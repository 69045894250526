import { Game, Route } from "../types";
import {
  collectTrick,
  isCardLegalInLastRound,
  makePassingMove,
  nextRound,
  playCard,
} from "./";
import { getTotalScore } from "./getTotalScore";

export const nextGameAction = (game: Game, route: Route) => {
  const {
    activePlayerIndex,
    cardsPlayedInLastRound,
    firstCardPlayed,
    players,
    round,
  } = game;
  const player = players[activePlayerIndex];
  const playedCards = players.filter(({ cardPlayed }) => cardPlayed);
  const numberOfPlayedCards = playedCards.length;
  if (players[activePlayerIndex].uid) {
    if (route !== Route.GAME_SCORE && round < 6 && numberOfPlayedCards === 4) {
      const gameWithCollectedTrick = collectTrick(game);
      if (player.hand.length === 0) {
        return nextRound(gameWithCollectedTrick);
      }
      if (
        game.settings.endRoundEarly &&
        getTotalScore(gameWithCollectedTrick) === -8
      ) {
        return nextRound(gameWithCollectedTrick);
      }
      return gameWithCollectedTrick;
    }
    if (
      route !== Route.GAME_SCORE &&
      round === 6 &&
      cardsPlayedInLastRound.length === 32
    ) {
      return nextRound(collectTrick(game));
    }
    return;
  }
  if (route !== Route.GAME_SCORE) {
    if (round === 6) {
      if (cardsPlayedInLastRound.length === 32) {
        return nextRound(collectTrick(game));
      }
      const legalCardsInLastRound = player.hand.filter(c =>
        isCardLegalInLastRound(c, cardsPlayedInLastRound),
      );
      if (legalCardsInLastRound.length > 0) {
        const cardToPlay =
          legalCardsInLastRound[
            Math.floor(Math.random() * legalCardsInLastRound.length)
          ];
        return playCard(game, cardToPlay, false);
      }
      return makePassingMove(game);
    }
    if (numberOfPlayedCards === 4) {
      const gameWithCollectedTrick = collectTrick(game);
      if (player.hand.length === 0) {
        return nextRound(gameWithCollectedTrick);
      }
      if (
        game.settings.endRoundEarly &&
        getTotalScore(gameWithCollectedTrick) === -8
      ) {
        return nextRound(gameWithCollectedTrick);
      }
      return gameWithCollectedTrick;
    }
    if (numberOfPlayedCards === 0) {
      // There are no cards on the table yet. So the player can pick any card he want's to play.
      const cardToPlay =
        player.hand[Math.floor(Math.random() * player.hand.length)];
      return playCard(game, cardToPlay, true);
    }
    // There are cards on the table. The first card determines the color which has to be played.
    const legalCards = player.hand.filter(
      ({ color }) => color === firstCardPlayed!.color,
    );
    if (legalCards.length === 0) {
      // The player has no cards of the same color on his hand, so he can play any card.
      const cardToPlay =
        player.hand[Math.floor(Math.random() * player.hand.length)];
      return playCard(game, cardToPlay, false);
    }
    // The player has some cards of the same color on his hand, he has to play one of those.
    const card = legalCards[Math.floor(Math.random() * legalCards.length)];
    return playCard(game, card, false);
  }
};

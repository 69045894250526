import React, { memo } from "react";

import { Card } from "../../components/Card";
import styled from "../../styled-components";
import { PlayerCardsProps, StyledCardProps } from "./shared";

const StyledTopPlayerName = styled.span`
  grid-column: 1 / 25;
  grid-row: 3 / 4;
  text-align: center;
  margin: ${props => props.theme.spacings._16} 0;
`;

export const TopPlayerName = memo(StyledTopPlayerName);

const getColumnForTopPlayerCard = ({
  numberOfCards,
  index,
}: StyledCardProps) => {
  const start = 12 - numberOfCards + 2 * index;
  const end = start + 4;
  return `${start} / ${end}`;
};

const StyledTopPlayerCard = styled(Card)<StyledCardProps>`
  height: 50px;
  width: 28px;
  grid-column: ${({ index, numberOfCards }) =>
    getColumnForTopPlayerCard({ index, numberOfCards })};
  grid-row: 4 / 6;

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    height: 100px;
    width: 56px;
  }
`;

export const TopPlayerCards: React.FC<PlayerCardsProps> = memo(
  ({ hand }) => (
    <>
      {hand.map((card, cardIndex) => (
        <StyledTopPlayerCard
          key={`topPlayerCard${cardIndex}`}
          index={cardIndex}
          numberOfCards={hand.length}
          isFacedDown
          {...card}
        />
      ))}
    </>
  ),
  (prevProps, props) => prevProps.hand.length === props.hand.length,
);

TopPlayerCards.displayName = "memo(TopPlayerCards)";

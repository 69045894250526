import { Card } from "../../components/Card";
import styled from "../../styled-components";
import { Card as CardType } from "../../types";

export type StyledCardProps = {
  index: number;
  numberOfCards: number;
};

export type PlayerCardsProps = {
  hand: CardType[];
};

export const getRowForSidePlayerCard = ({
  numberOfCards,
  index,
}: StyledCardProps) => {
  const start = 16 - numberOfCards + 2 * index;
  const end = start + 4;
  return `${start} / ${end}`;
};

export const StyledCard = styled(Card)`
  height: 100px;
  width: 56px;
  cursor: ${props => (props.onClick ? "pointer" : "default")};
`;

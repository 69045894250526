import React from "react";

import { theme } from "../../theme";

type SchellenProps = {
  showPulse?: boolean;
};

export const Schellen: React.FC<SchellenProps> = ({ showPulse }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" width="66" height="72">
    <g style={{ transformOrigin: "center" }}>
      {showPulse && (
        <>
          <animateTransform
            id="svg-schellen-pulse-1"
            attributeName="transform"
            type="scale"
            from="1"
            to="0.8"
            dur="0.25s"
            begin="0.75s;svg-schellen-pulse-1.end+0.75s"
          />
          <animateTransform
            id="svg-schellen-pulse-2"
            attributeName="transform"
            type="scale"
            from="0.8"
            to="1"
            dur="0.25s"
            begin="1s;svg-schellen-pulse-2.end+0.75s"
          />
        </>
      )}
      <circle
        stroke={theme.colors.black}
        fill={theme.colors.green}
        cx="33"
        cy="65"
        r="5"
      />
      <path
        fill="none"
        stroke={theme.colors.black}
        d="M33 65v5M35 65v4M37 65v3"
      />
      <circle fill={theme.colors.yellow} cx="33" cy="33" r="32" />
      <path
        fill={theme.colors.red[500]}
        d="M2.5 23h61q3.5 10 0 20h-61q-3.5-10 0-20"
      />
      <path
        fill="none"
        stroke={theme.colors.black}
        d="M63 23v20M6 43v7M9 43v11M12 43v11M15 43v11M18 43v11M21 43v11M24 43v11M27 43v11M30 43v11M33 23v31M36 23v31M39 23v31M42 23v31M45 23v31M48 23v31M51 23v31M54 23v31M57 23v31M60 23v27"
      />
      <path
        stroke={theme.colors.black}
        fill={theme.colors.green}
        d="M27 48q-1.5-2.5-8-5-4 2-4 7l14 15M39 48q1.5-2.5 8-5 4 2 4 7L37 65"
      />
      <path
        stroke={theme.colors.black}
        fill={theme.colors.green}
        d="M29 65q-9-15 4-22 13 7 4 22"
      />
      <path fill={theme.colors.green} d="M3.1 43h-.3q3.7 17 26.6 22" />
      <path
        stroke={theme.colors.black}
        fill={theme.colors.green}
        d="M29 65q-8-13-14-15-7-3-12.2-7"
      />
      <path fill={theme.colors.green} d="M62.9 43h.3q-3.7 17-26.6 22" />
      <path
        stroke={theme.colors.black}
        fill={theme.colors.green}
        d="M37 65q8-13 14-15 7-3 12.2-7"
      />
      <path stroke={theme.colors.black} fill="none" d="M33 43v22" />
      <circle stroke={theme.colors.black} fill="none" cx="33" cy="33" r="32" />
      <path
        stroke={theme.colors.black}
        fill="none"
        d="M33 1v5.2M35 1.2V6M31 1.2V6M37 1.2V5M29 1.2V5M28 1.2q0 5.3 5 5.3t5-5.3M2.8 23h60.4M2.8 43h60.4M33 3h11.5M33 6h17M33 9h21M33 12h24M33 15h26.5M33 18h28M33 21h30"
      />
      <circle stroke={theme.colors.black} fill="none" cx="10" cy="33" r="5" />
      <path
        stroke={theme.colors.black}
        fill="none"
        d="M5 33h10M5.2 31h9.6M5.2 35h9.6M6.8 29h6.4M6.8 37h6.4"
      />
      <circle stroke={theme.colors.black} fill="none" cx="33" cy="33" r="5" />
      <path
        stroke={theme.colors.black}
        fill="none"
        d="M28 33h10M28.2 31h9.6M28.2 35h9.6M29.8 29h6.4M29.8 37h6.4"
      />
      <circle stroke={theme.colors.black} fill="none" cx="56" cy="33" r="5" />
      <path
        stroke={theme.colors.black}
        fill="none"
        d="M51 33h10M51.2 31h9.6M51.2 35h9.6M52.8 29h6.4M52.8 37h6.4M33 63h11.5M33 60h17M33 57h21M33 54h24M33 51h26.5M33 48h28M33 45h30"
      />
    </g>
  </svg>
);

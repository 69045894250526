import { Card, Game } from "../types";
import { getSortValueForCard } from "./cardList";

const isValueGreaterThan = (firstCard: Card, secondCard: Card) =>
  getSortValueForCard(firstCard) > getSortValueForCard(secondCard);

export const collectTrick = (game: Game) => {
  if (game.round === 6) return game;

  const playerWithHighestCard = game.players.reduce(
    (acc, p, index) => {
      if (p.cardPlayed!.color !== game.firstCardPlayed!.color) {
        return acc;
      }
      if (!acc.card) return { index, card: p.cardPlayed };
      if (isValueGreaterThan(p.cardPlayed!, acc.card)) {
        return { index, card: p.cardPlayed };
      }
      return acc;
    },
    { index: -1, card: null } as { index: number; card: Card | null },
  );
  const playedCards = game.players.map(player => player.cardPlayed!);
  const trickIndex = 7 - game.players[0].hand.length;
  const gameUpdate: Game = {
    ...game,
    activePlayerIndex: playerWithHighestCard.index,
    firstCardPlayed: null,
    players: game.players.map((player, index) => ({
      ...player,
      cardPlayed: null,
      indicesOfTricks: [
        ...player.indicesOfTricks,
        ...(index === playerWithHighestCard.index ? [trickIndex] : []),
      ],
      tricks: [
        ...player.tricks,
        ...(index === playerWithHighestCard.index ? playedCards : []),
      ],
    })),
  };
  return gameUpdate;
};

import { Game } from "../types";
import { getActivePlayerIndexInLastRound } from "./";

export const makePassingMove = (game: Game) => {
  const gameWithPassedMove: Game = {
    ...game,
    activePlayerIndex: getActivePlayerIndexInLastRound(game),
  };
  return gameWithPassedMove;
};

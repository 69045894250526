import * as firebase from "firebase/app";
import "firebase/auth";
import React, { createContext, useEffect, useReducer } from "react";

import { wait } from "../utils";

type AuthState = { doc: firebase.User | null; isLoading: boolean };

type AuthAction =
  | { type: "LOADING_USER_DONE"; payload: firebase.User | null }
  | { type: "UPDATE_FIREBASE_USER" };

type AuthContextType = [AuthState, React.Dispatch<AuthAction>];

const initialState: AuthState = { doc: null, isLoading: true };

const initialValue: AuthContextType = [initialState, () => null];

export const AuthContext = createContext(initialValue);

const reducer = (state: AuthState, action: AuthAction) => {
  let newState: AuthState;
  switch (action.type) {
    case "LOADING_USER_DONE":
      newState = {
        ...state,
        doc: action.payload,
        isLoading: false,
      };
      break;
    case "UPDATE_FIREBASE_USER":
      newState = {
        ...state,
        doc: firebase.auth().currentUser,
      };
      break;
    default:
      newState = state;
      break;
  }
  return newState;
};

export const AuthContextProvider: React.FC = ({ children }) => {
  const auth = firebase.auth();

  const [authState, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const waitPromise = wait(1500);
    return auth.onAuthStateChanged(async changedUser => {
      await waitPromise;
      dispatch({ type: "LOADING_USER_DONE", payload: changedUser });
    });
  }, [auth]);

  return (
    <AuthContext.Provider value={[authState, dispatch]}>
      {children}
    </AuthContext.Provider>
  );
};

import React, { createContext, useEffect, useState } from "react";

import { NetworkStatus } from "../types";

const initialState = NetworkStatus.ONLINE;

export const NetworkContext = createContext<NetworkStatus>(initialState);

export const NetworkContextProvider: React.FC = ({ children }) => {
  const [networkStatus, setNetworkStatus] = useState(initialState);
  const handleOnline = () => setNetworkStatus(NetworkStatus.ONLINE);
  const handleOffline = () => setNetworkStatus(NetworkStatus.OFFLINE);
  useEffect(() => {
    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);
    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);
  return (
    <NetworkContext.Provider value={networkStatus}>
      {children}
    </NetworkContext.Provider>
  );
};

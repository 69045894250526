import React, { useState } from "react";

type FormValues = { [key: string]: string };

export const useForm = (
  form: React.RefObject<HTMLFormElement>,
  fieldList: string[],
  submit: (
    formValues: FormValues,
    setState: React.Dispatch<React.SetStateAction<null | string>>,
    setSuccess: React.Dispatch<React.SetStateAction<null | string>>,
  ) => Promise<false | void>,
) => {
  const [error, setError] = useState<null | string>(null);
  const [success, setSuccess] = useState<null | string>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  return {
    error,
    isSubmitting,
    reset: () => {
      fieldList.forEach(fieldName => {
        if (form.current) {
          form.current[fieldName].value = "";
        }
      });
      setError(null);
      setSuccess(null);
    },
    submit: async (event: React.FormEvent) => {
      event.preventDefault();

      if (isSubmitting) return;

      const formElement = event.target as HTMLFormElement;
      const formValues = fieldList.reduce(
        (acc, fieldName) => {
          acc[fieldName] = formElement[fieldName].value;
          return acc;
        },
        {} as FormValues,
      );
      setIsSubmitting(true);
      const shouldUpdateSubmitting = await submit(
        formValues,
        setError,
        setSuccess,
      );
      if (shouldUpdateSubmitting !== false) setIsSubmitting(false);
    },
    success,
  };
};

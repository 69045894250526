import React, { useContext } from "react";

import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Loading } from "../../components/Loading";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { StyledHeading } from "../../components/StyledHeading";
import { UserContext } from "../../context";

const round = (number: number, decimals: number) =>
  Math.floor(Math.round(number * 10 ** decimals)) / 10 ** decimals;

export const Statistics: React.FC = () => {
  const [user] = useContext(UserContext);
  if (!user.data) {
    return <Loading />;
  }
  return (
    <Container>
      <StyledLinkButton
        onClick={() => {
          window.history.back();
        }}
      >
        zurück
      </StyledLinkButton>
      <Divider />
      <StyledHeading>Deine Statistiken</StyledHeading>
      <ul>
        <li>Gespielte Spiele: {user.data.gamesPlayed}</li>
        <li>Punktestand: {user.data.runningScore}</li>
        <li>
          Punkte pro Spiel:{" "}
          {round(user.data.runningScore / user.data.gamesPlayed, 2) || 0}
        </li>
      </ul>
    </Container>
  );
};

import { navigate } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/auth";
import React, { useEffect, useRef } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Input } from "../../components/Input";
import { Stack } from "../../components/Stack";
import { StyledErrorMessage } from "../../components/StyledErrorMessage";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { useForm, useToggle } from "../../hooks";
import styled from "../../styled-components";
import { Route } from "../../types";
import { delayPromiseAtLeast, mapErrorToMessage } from "../../utils";

const StyledStack = styled(Stack)`
  padding: ${props => props.theme.spacings._16};
`;

const StyledHeading = styled.h1`
  font-size: ${props => props.theme.fontSizes._48};
  letter-spacing: ${props => props.theme.spacings._4};
  text-align: center;
`;

const StyledSuccessMessage = styled.p`
  color: ${props => props.theme.colors.green};
`;

export const Authentication: React.FC = () => {
  const form = useRef<HTMLFormElement>(null);
  const [showRegistration, toggleRegistration] = useToggle(false);
  const { error, isSubmitting, reset, submit, success } = useForm(
    form,
    ["email", "password"].concat(showRegistration ? "passwordRepeat" : []),
    async ({ email, password, passwordRepeat }, setError) => {
      if (email === "") return setError("Bitte Email eingeben");
      if (password === "") return setError("Bitte Passwort eingeben");
      if (showRegistration && passwordRepeat === "") {
        return setError("Bitte Passwort wiederholen");
      }
      if (showRegistration && passwordRepeat !== password) {
        return setError("Die Passwörter stimmen nicht überein");
      }
      setError(null);

      try {
        if (showRegistration) {
          await delayPromiseAtLeast(
            firebase.auth().createUserWithEmailAndPassword(email, password),
            1500,
          );
        } else {
          await delayPromiseAtLeast(
            firebase.auth().signInWithEmailAndPassword(email, password),
            1500,
          );
        }
        return false;
      } catch (error) {
        setError(mapErrorToMessage(error));
      }
    },
  );
  useEffect(() => () => navigate(Route.DASHBOARD), []);
  return (
    <StyledStack scale="_32">
      <StyledHeading>Servus beim Karalaxen!</StyledHeading>
      <Divider />
      {showRegistration ? (
        <>
          <Container scale="_32">
            <StyledLinkButton onClick={toggleRegistration} onMouseUp={reset}>
              zur Anmeldung
            </StyledLinkButton>
          </Container>
          <Divider />
        </>
      ) : (
        <Container as="p">Erst mal anmelden:</Container>
      )}
      <Container scale="_32">
        <form onSubmit={submit} ref={form}>
          <Stack scale="_12">
            <label htmlFor="email">Email:</label>
            <Input id="email" type="email" />
            <label htmlFor="password">Passwort:</label>
            <Input id="password" type="password" />
            {showRegistration && (
              <>
                <label htmlFor="passwordRepeat">Passwort wiederholen:</label>
                <Input id="passwordRepeat" type="password" />
              </>
            )}
            <ButtonWrapper>
              <Button isLoading={isSubmitting} priority="primary" type="submit">
                {showRegistration ? "Registrieren" : "Anmelden"}
              </Button>
            </ButtonWrapper>
            {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
            {success && <StyledSuccessMessage>{success}</StyledSuccessMessage>}
          </Stack>
        </form>
      </Container>
      {!showRegistration && (
        <>
          <Divider />
          <Container scale="_32">
            <p>Noch keinen Account?</p>
            <ButtonWrapper>
              <Button onClick={toggleRegistration} onMouseUp={reset}>
                Registrieren
              </Button>
            </ButtonWrapper>
          </Container>
        </>
      )}
    </StyledStack>
  );
};

import { navigate, RouteComponentProps } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useEffect, useRef } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Card } from "../../components/Card";
import { Container } from "../../components/Container";
import { GameLinks } from "../../components/GameLinks";
import { Loading } from "../../components/Loading";
import {
  AuthContext,
  MultiPlayerGamesContext,
  UserContext,
} from "../../context";
import { useRedirectOnNetworkStatusChange } from "../../hooks";
import styled, { css } from "../../styled-components";
import { Game as GameType, NetworkStatus, Route, Settings } from "../../types";
import {
  defaultSettings,
  isCardLegalInLastRound,
  makePassingMove,
  nextGameAction,
} from "../../utils";
import { BottomPlayerCards, BottomPlayerName } from "./BottomPlayer";
import { LastRound } from "./LastRound";
import { LeftPlayerCards, LeftPlayerName } from "./LeftPlayer";
import { RightPlayerCards, RightPlayerName } from "./RightPlayer";
import { StyledCard } from "./shared";
import { TopPlayerCards, TopPlayerName } from "./TopPlayer";

type StyledContainerProps = {
  hasTwoCardRows: boolean;
};

const StyledContainer = styled.div<StyledContainerProps>`
  display: grid;
  grid-template-rows: auto auto auto 37.5px 12.5px 12.5px 75px repeat(18, 7px) 30px auto 100px ${props =>
      props.hasTwoCardRows ? "100px" : ""} auto 1fr;
  grid-template-columns: 37.5px 12.5px 1fr repeat(18, 7px) 1fr 12.5px 37.5px;
  max-width: ${props => props.theme.breakpoints._800};
  margin: 0 auto;

  @media (min-width: ${props => props.theme.breakpoints._520}) {
    grid-template-rows: auto auto auto 37.5px 12.5px 12.5px 75px repeat(18, 7px) 30px auto 100px auto 1fr;
  }

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    grid-template-rows: auto auto auto 75px 25px 25px 50px repeat(18, 14px) 2px auto 100px ${props =>
        props.hasTwoCardRows ? "100px" : ""} auto 1fr;
    grid-template-columns: 75px 25px 1fr repeat(18, 14px) 1fr 25px 75px;
  }

  @media (min-width: ${props =>
      props.theme.breakpoints._520}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    grid-template-rows: auto auto auto 75px 25px 25px 50px repeat(18, 14px) 2px auto 100px auto 1fr;
  }
`;

type StyledBackgroundProps = {
  gridColumn: string;
  gridRow: string;
};

const BackgroundStyles = css`
  background-color: ${props => props.theme.colors.gray[500]};
  box-shadow: 0 0 8px 8px ${props => props.theme.colors.gray[500]};
`;

const StyledActivePlayerBackground = styled.div<StyledBackgroundProps>`
  ${BackgroundStyles}
  grid-column: ${props => props.gridColumn};
  grid-row: ${props => props.gridRow};
`;

const StyledTopPlayerPlayedCard = styled(Card)`
  height: 100px;
  width: 56px;
  grid-column: 9 / 17;
  grid-row: 5 / 8;

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    grid-column: 11 / 15;
  }
`;

const StyledLeftPlayerPlayedCardContainer = styled.div`
  grid-column: 2 / 8;
  grid-row: 8 / 26;
  display: flex;
  align-items: center;
`;

const StyledRightPlayerPlayedCardContainer = styled.div`
  grid-column: 18 / 24;
  grid-row: 8 / 26;
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

type StyledBottomPlayerCardRowProps = {
  isActive: boolean;
};

const StyledBottomPlayerCardRow = styled.div<StyledBottomPlayerCardRowProps>`
  grid-row: 28 / 30;
  grid-column: 1 / 25;
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0 auto;
  width: 224px;
  ${props => props.isActive && BackgroundStyles}

  @media(min-width: ${props => props.theme.breakpoints._520}) {
    width: 448px;
    height: 100px;
  }
`;

const StyledBottomPlayerPlayedCard = styled(Card)`
  height: 100px;
  width: 56px;
  grid-column: 9 / 17;
  grid-row: 16 / 27;

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    grid-column: 11 / 15;
    grid-row: 19 / 27;
  }
`;

const StyledButton = styled(Button)`
  grid-column: 1 / 25;
  grid-row: 30 / 31;
  margin: ${props => props.theme.spacings._16} auto;
`;

type Props = {
  gameId?: string;
};

export const Game: React.FC<RouteComponentProps<Props>> = ({ gameId }) => {
  useRedirectOnNetworkStatusChange(
    NetworkStatus.OFFLINE,
    Route.MULTI_PLAYER,
    Boolean(gameId),
  );
  const [firebaseUser] = useContext(AuthContext);
  const [user] = useContext(UserContext);
  const { isLoading, games } = useContext(MultiPlayerGamesContext);
  const settings: Settings = user.data ? user.data.settings : defaultSettings;

  useEffect(() => {
    if (!user.data || !firebaseUser.doc || isLoading) {
      return;
    }
    const { uid: currentUid } = firebaseUser.doc;

    const activeGame = gameId ? games[gameId] : user.data.activeGame;
    if (!activeGame) return;

    const previousRound = activeGame.round;
    const gameUpdate = nextGameAction(activeGame, (gameId
      ? window.location.pathname.replace(`/${gameId}`, "")
      : window.location.pathname) as Route);
    if (gameUpdate) {
      setTimeout(() => {
        if (previousRound < gameUpdate.round) {
          navigate(gameId ? `${Route.GAME_SCORE}/${gameId}` : Route.GAME_SCORE);
        }
        if (activeGame.createdBy !== currentUid) {
          return;
        }
        if (gameId) {
          firebase
            .firestore()
            .collection("games")
            .doc(gameId)
            .update(gameUpdate);
        } else if (user.ref) {
          user.ref.update({ activeGame: gameUpdate });
        }
      }, settings.gameSpeed);
    }
  });

  const previousGame = useRef<GameType | null>(null);

  if (previousGame.current && gameId && !isLoading && !games[gameId]) {
    return (
      <Container>
        <p>
          Dieses Spiel existiert nicht. Möglicherweise wurde es vom Spielleiter
          gerade gelöscht.
        </p>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(Route.MULTI_PLAYER);
            }}
            priority="primary"
          >
            zur Übersicht
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }

  if (!user.data || !firebaseUser.doc || isLoading) {
    return <Loading />;
  }

  const game = gameId ? games[gameId] : user.data.activeGame;
  if (!game) {
    return (
      <Container>
        <p>
          {gameId
            ? "Dieses Spiel existiert nicht."
            : "Du hast kein Spiel am laufen."}
        </p>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(gameId ? Route.MULTI_PLAYER : Route.DASHBOARD);
            }}
            priority="primary"
          >
            zur Übersicht
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }

  previousGame.current = game;

  const { uid } = firebaseUser.doc;
  const currentPlayerIndex = game.players.findIndex(
    player => player.uid === uid,
  );

  if (currentPlayerIndex < 0) {
    return (
      <Container>
        <p>Du nimmst nicht an diesem Spiel teil.</p>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(gameId ? Route.MULTI_PLAYER : Route.DASHBOARD);
            }}
            priority="primary"
          >
            zur Übersicht
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }

  const currentPlayer = game.players[currentPlayerIndex];

  if (game.round > 6) {
    return (
      <StyledContainer hasTwoCardRows={false}>
        <GameLinks game={game} gameId={gameId} />
      </StyledContainer>
    );
  }

  let gridColumn = "";
  let gridRow = "";

  if (game.activePlayerIndex === (currentPlayerIndex + 1) % 4) {
    gridColumn = "1 / 3";
    gridRow = "8 / 26";
  } else if (game.activePlayerIndex === (currentPlayerIndex + 2) % 4) {
    gridColumn = "4 / 22";
    gridRow = "4 / 6";
  } else if (game.activePlayerIndex === (currentPlayerIndex + 3) % 4) {
    gridColumn = "23 / 25";
    gridRow = "8 / 26";
  }

  return (
    <StyledContainer hasTwoCardRows={currentPlayer.hand.length > 4}>
      <GameLinks game={game} gameId={gameId} />
      {game.activePlayerIndex !== currentPlayerIndex && (
        <StyledActivePlayerBackground
          gridColumn={gridColumn}
          gridRow={gridRow}
        />
      )}
      {game.players.map((player, playerIndex) => {
        if (playerIndex === (currentPlayerIndex + 2) % 4) {
          // this player is the player at the top
          return (
            <Fragment key="topPlayer">
              <TopPlayerName>{player.name}</TopPlayerName>
              <TopPlayerCards hand={player.hand} />
              {player.cardPlayed && (
                <StyledTopPlayerPlayedCard
                  key="topPlayerCardPlayed"
                  {...player.cardPlayed}
                />
              )}
            </Fragment>
          );
        }
        if (playerIndex === (currentPlayerIndex + 1) % 4) {
          // this player is the player at the left side
          return (
            <Fragment key="leftPlayer">
              <LeftPlayerName>{player.name}</LeftPlayerName>
              <LeftPlayerCards hand={player.hand} />
              <StyledLeftPlayerPlayedCardContainer>
                {player.cardPlayed && <StyledCard {...player.cardPlayed} />}
              </StyledLeftPlayerPlayedCardContainer>
            </Fragment>
          );
        }
        if (playerIndex === (currentPlayerIndex + 3) % 4) {
          // this player is the player at the right side
          return (
            <Fragment key="rightPlayer">
              <RightPlayerName>{player.name}</RightPlayerName>
              <RightPlayerCards hand={player.hand} />
              <StyledRightPlayerPlayedCardContainer>
                {player.cardPlayed && <StyledCard {...player.cardPlayed} />}
              </StyledRightPlayerPlayedCardContainer>
            </Fragment>
          );
        }
        // this player is the player at the bottom
        const isActive = game.activePlayerIndex === currentPlayerIndex;
        const hasLegalCardsInLastRound =
          player.hand.filter(card =>
            isCardLegalInLastRound(card, game.cardsPlayedInLastRound),
          ).length > 0;
        return (
          <Fragment key="bottomPlayer">
            <BottomPlayerName>{player.name}</BottomPlayerName>
            <StyledBottomPlayerCardRow isActive={isActive}>
              <BottomPlayerCards
                game={game}
                gameId={gameId}
                hand={player.hand}
                highlightLegalCards={settings.highlightLegalCards}
                isActive={isActive}
                sortCards={settings.sortCards}
                userRef={user.ref}
              />
            </StyledBottomPlayerCardRow>
            {player.cardPlayed && (
              <StyledBottomPlayerPlayedCard {...player.cardPlayed} />
            )}
            {game.round === 6 &&
              isActive &&
              !hasLegalCardsInLastRound &&
              player.hand.length !== 0 && (
                <StyledButton
                  onClick={() => {
                    if (!gameId && !user.ref) return;
                    if (gameId) {
                      firebase
                        .firestore()
                        .collection("games")
                        .doc(gameId)
                        .update(makePassingMove(game));
                    } else if (user.ref) {
                      user.ref.update({ activeGame: makePassingMove(game) });
                    }
                  }}
                  data-testid="next-click"
                >
                  kann nicht
                </StyledButton>
              )}
          </Fragment>
        );
      })}
      {game.round === 6 && (
        <LastRound cardsPlayed={game.cardsPlayedInLastRound} />
      )}
    </StyledContainer>
  );
};

import { Settings, User } from "../types";

export const defaultSettings: Settings = {
  endRoundEarly: false,
  gameSpeed: 500,
  highlightLegalCards: false,
  sortCards: false,
};

export const createUser = () => {
  const user: User = {
    activeGame: null,
    gamePlacements: {
      1: 0,
      2: 0,
      3: 0,
      4: 0,
    },
    gamesPlayed: 0,
    runningScore: 0,
    settings: { ...defaultSettings },
  };
  return user;
};

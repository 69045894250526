/* tslint:disable object-literal-sort-keys */

export const theme = {
  breakpoints: {
    _452: "452px",
    _520: "520px",
    _800: "800px",
    _860: "860px",
  },
  borders: {
    _2: "2px",
    _4: "4px",
  },
  colors: {
    black: "hsl(0, 0%, 0%)",
    blue: "hsl(240, 100%, 43.7%)",
    gray: {
      500: "hsl(0, 0%, 50%)",
      700: "hsl(0, 0%, 75%)",
      800: "hsl(0, 0%, 87.5%)",
    },
    green: "hsl(120, 100%, 24.9%)",
    red: {
      400: "hsl(0, 100%, 43.7%)",
      500: "hsl(0, 100%, 50%)",
    },
    white: "hsl(0, 0%, 100%)",
    yellow: "hsl(60, 100%, 50%)",
  },
  content: {
    height: "calc(100vh - 32px)",
    padding: "16px",
  },
  fontSizes: {
    _12: "12px",
    _14: "14px",
    _16: "16px",
    _18: "18px",
    _20: "20px",
    _24: "24px",
    _30: "30px",
    _36: "36px",
    _48: "48px",
  },
  spacings: {
    _4: "4px",
    _8: "8px",
    _12: "12px",
    _16: "16px",
    _24: "24px",
    _32: "32px",
    _48: "48px",
    _64: "64px",
    _128: "128px",
    _192: "192px",
    _256: "256px",
    _384: "384px",
  },
};

import React from "react";

import styled from "../../styled-components";

export const StyledDivider = styled.div`
  border-top: ${props => props.theme.borders._2} solid
    ${props => props.theme.colors.gray[500]};
`;

export const Divider: React.FC = ({ children, ...props }) => (
  <StyledDivider {...props} aria-hidden="true" />
);

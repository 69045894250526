import * as firebase from "firebase/app";
import "firebase/firestore";

import { Game, Player } from "../types";
import { nameList } from "./nameList";
import { shuffleCards } from "./shuffleCards";
import { OpeningPlayer, Settings } from "../types";

export const createGame = (
  creator: OpeningPlayer,
  players: OpeningPlayer[],
  settings: Settings,
): Game => {
  const shuffledCards = shuffleCards();
  const allPlayers = [
    creator,
    ...players,
    ...Array.from({ length: 3 - players.length }).map(() => null),
  ]
    .map(player => ({ player, sort: Math.random() }))
    .sort((p1, p2) => p1.sort - p2.sort)
    .map(({ player }) => player);
  return {
    activePlayerIndex: 0,
    authorizedUsers: [creator.uid, ...players.map(player => player.uid)],
    cardsPlayedInLastRound: [],
    createdBy: creator.uid,
    firstCardPlayed: null,
    initialPlayerIndex: 0,
    players: allPlayers.map((openingPlayer, index) => {
      const player: Player = {
        cardPlayed: null,
        finishedInPlace: null,
        hand: shuffledCards.slice(index * 8, (index + 1) * 8),
        indicesOfTricks: [],
        name: openingPlayer
          ? openingPlayer.name
          : nameList[Math.floor(Math.random() * nameList.length)],
        scorePerRound: [],
        tricks: [],
        uid: openingPlayer ? openingPlayer.uid : null,
      };
      return player;
    }),
    round: 0,
    settings,
    timestamp: firebase.firestore.Timestamp.fromDate(new Date()),
  };
};

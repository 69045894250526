import React, { memo } from "react";

import styled from "../../styled-components";
import { theme } from "../../theme";
import { Card as CardType } from "../../types";
import { Backside, BacksideProps } from "./Backside";
import { mapCardColorToComponent } from "./mapCardColorToComponent";

const StyledText = styled.text`
  font-size: ${props => props.theme.fontSizes._24} serif;
`;

type CardProps = CardType &
  BacksideProps & {
    isFacedDown?: boolean;
    isIllegal?: boolean;
    onClick?: (card: CardType) => void;
    testId?: string;
  };

export const Card: React.FC<CardProps> = memo(
  ({
    className,
    color,
    isFacedDown,
    isHorizontal,
    isIllegal,
    onClick,
    testId,
    value,
  }) => {
    if (isFacedDown) {
      return <Backside className={className} isHorizontal={isHorizontal} />;
    }
    const ColorComponent = mapCardColorToComponent(color);
    return (
      <svg
        onClick={onClick ? () => onClick({ color, value }) : undefined}
        className={className}
        height={isHorizontal ? "56" : "100"}
        width={isHorizontal ? "100" : "56"}
        viewBox={isHorizontal ? "0 0 100 56" : "0 0 56 100"}
        xmlns="http://www.w3.org/2000/svg"
        {...(testId ? { "data-testid": testId } : {})}
      >
        <g transform={isHorizontal ? "rotate(90) translate(0 -100)" : ""}>
          <rect
            x="1"
            y="1"
            width="54"
            height="98"
            rx="5"
            ry="5"
            stroke={theme.colors.black}
            strokeWidth="2"
            fill={isIllegal ? theme.colors.gray[700] : theme.colors.white}
          />
          <ColorComponent />
          <StyledText x="28" y="85" textAnchor="middle">
            {value}
          </StyledText>
        </g>
      </svg>
    );
  },
);

Card.displayName = "memo(Card)";

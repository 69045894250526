import * as firebase from "firebase/app";
import "firebase/firestore";
import { Opening } from "../types";

export const createOpening = (uid: string, name: string) => {
  const newMultiPlayerGame: Opening = {
    applicants: [],
    createdAt: firebase.firestore.Timestamp.now(),
    name,
    players: [],
    uid,
  };
  return newMultiPlayerGame;
};

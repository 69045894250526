import React from "react";

import styled from "../../styled-components";

export const StyledLinkButtonComponent = styled.button`
  background: none;
  border: none;
  margin: 0;
  padding: 0;
  text-align: left;
  text-decoration: underline;
  color: ${props => props.theme.colors.blue};
  cursor: pointer;
`;

type Props = {
  onClick?: React.HTMLProps<HTMLButtonElement>["onClick"];
  onMouseUp?: React.HTMLProps<HTMLButtonElement>["onMouseUp"];
};

export const StyledLinkButton: React.FC<Props> = props => (
  <StyledLinkButtonComponent type="button" {...props} />
);

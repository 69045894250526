import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { createContext, useEffect, useState } from "react";

import { Opening } from "../types";

type State = { isLoading: boolean; openings: Opening[] };

const initialState: State = { isLoading: true, openings: [] };

export const OpeningsContext = createContext(initialState);

export const OpeningsContextProvider: React.FC = ({ children }) => {
  const [openings, setOpenings] = useState(initialState);
  useEffect(
    () =>
      firebase
        .firestore()
        .collection("openings")
        .limit(11)
        .onSnapshot(querySnapshot => {
          const currentOpenings: Opening[] = [];
          querySnapshot.forEach(doc =>
            currentOpenings.push(doc.data() as Opening),
          );
          setOpenings({ isLoading: false, openings: currentOpenings });
        }),
    [],
  );
  return (
    <OpeningsContext.Provider value={openings}>
      {children}
    </OpeningsContext.Provider>
  );
};

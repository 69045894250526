import * as firebase from "firebase/app";
import "firebase/firestore";

import { Game, Result } from "../types";

export const finishGame = async (game: Game, gameId: string | undefined) => {
  const indexOfLastRound = 6;
  await Promise.all(
    game.players.map(async player => {
      if (!player.uid) return;

      const score = player.scorePerRound[indexOfLastRound];
      const place =
        game.players
          .map(player => player.scorePerRound[indexOfLastRound])
          .filter(playerScore => playerScore > score).length + 1;
      const result: Result = {
        place,
        score,
        uid: player.uid,
      };
      return firebase
        .firestore()
        .collection("results")
        .add(result);
    }),
  );

  return gameId
    ? firebase
        .firestore()
        .collection("games")
        .doc(gameId)
        .delete()
    : firebase
        .firestore()
        .collection("users")
        .doc(game.createdBy)
        .update({ activeGame: null });
};

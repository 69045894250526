import { Card } from "../types";

export const getLowerCard = (card: Card) => {
  let lowerCard: Card | null;
  switch (card.value) {
    case "8":
      lowerCard = { color: card.color, value: "7" };
      break;
    case "9":
      lowerCard = { color: card.color, value: "8" };
      break;
    case "10":
      lowerCard = { color: card.color, value: "9" };
      break;
    case "U":
      lowerCard = { color: card.color, value: "10" };
      break;
    case "O":
      lowerCard = { color: card.color, value: "U" };
      break;
    case "K":
      lowerCard = { color: card.color, value: "O" };
      break;
    case "A":
      lowerCard = { color: card.color, value: "K" };
      break;
    default:
      lowerCard = null;
      break;
  }
  return lowerCard;
};

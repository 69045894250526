import * as styledComponents from "styled-components";

import { theme } from "./theme";

const {
  createGlobalStyle,
  css,
  default: styled,
  ThemeProvider,
} = styledComponents as styledComponents.ThemedStyledComponentsModule<
  typeof theme
>;

export { createGlobalStyle, css, ThemeProvider };

export default styled;

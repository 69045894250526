import { Link, navigate } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/auth";
import React, { memo, useState } from "react";

import { Button } from "../../components/Button";
import styled from "../../styled-components";
import { Route } from "../../types";

const signOut = () => {
  firebase.auth().signOut();
};

const StyledHeader = styled.header`
  background-color: ${props => props.theme.colors.black};
  color: ${props => props.theme.colors.white};
  position: sticky;
  top: 0;
`;

const StyledHeaderBar = styled.div`
  padding: ${props =>
    `${props.theme.spacings._12} ${props.theme.spacings._16}`};
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: ${props => props.theme.breakpoints._800};
  margin: 0 auto;
`;

const StyledHomeIcon = styled.span`
  font-size: ${props => props.theme.fontSizes._36};
`;

const StyledRouterLink = styled(Link)`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`;

const StyledLink = styled.a`
  color: ${props => props.theme.colors.white};
  text-decoration: none;
`;

const StyledMenuIcon = styled.span`
  font-size: ${props => props.theme.fontSizes._36};
  @media (min-width: ${props => props.theme.breakpoints._520}) {
    display: none;
  }
`;

const StyledMenuLinks = styled.div`
  display: none;
  > * + * {
    margin-left: ${props => props.theme.spacings._32};
  }
  @media (min-width: ${props => props.theme.breakpoints._520}) {
    display: block;
  }
`;

const StyledHeaderDropdown = styled.div`
  display: flex;
  flex-direction: column;
  > button + button {
    border-top: 0;
  }

  @media (min-width: ${props => props.theme.breakpoints._520}) {
    display: none;
  }
`;

type HeaderBarProps = {
  setShowHeaderMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const HeaderBar: React.FC<HeaderBarProps> = memo(({ setShowHeaderMenu }) => (
  <StyledHeaderBar>
    <StyledHomeIcon>
      <StyledRouterLink to={Route.DASHBOARD}>Karalaxen</StyledRouterLink>
    </StyledHomeIcon>
    <StyledMenuIcon onClick={() => setShowHeaderMenu(value => !value)}>
      &#9776;
    </StyledMenuIcon>
    <StyledMenuLinks>
      <StyledRouterLink to={Route.STATISTICS}>Statistiken</StyledRouterLink>
      <StyledRouterLink to={Route.SETTINGS}>Einstellungen</StyledRouterLink>
      <StyledLink href="#" onClick={signOut}>
        Abmelden
      </StyledLink>
    </StyledMenuLinks>
  </StyledHeaderBar>
));

HeaderBar.displayName = "memo(HeaderBar)";

export const Header: React.FC = memo(() => {
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  return (
    <StyledHeader>
      <HeaderBar setShowHeaderMenu={setShowHeaderMenu} />
      {showHeaderMenu && (
        <StyledHeaderDropdown
          onClick={() => setShowHeaderMenu(value => !value)}
        >
          <Button onClick={() => navigate(Route.STATISTICS)}>
            Statistiken
          </Button>
          <Button onClick={() => navigate(Route.SETTINGS)}>
            Einstellungen
          </Button>
          <Button onClick={signOut}>Abmelden</Button>
        </StyledHeaderDropdown>
      )}
    </StyledHeader>
  );
});

Header.displayName = "memo(Header)";

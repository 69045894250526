import { navigate, Redirect, RouteComponentProps, Router } from "@reach/router";
import React, { useContext } from "react";

import { Header } from "../../components/Header";
import { Loading } from "../../components/Loading";
import {
  AuthContext,
  MultiPlayerGamesContextProvider,
  OpeningsContextProvider,
  UserContextProvider,
} from "../../context";
import { theme } from "../../theme";
import { Route as RouteType } from "../../types";
import { Authentication } from "../Authentication";
import { Dashboard } from "../Dashboard";
import { DeleteAccount } from "../DeleteAccount";
import { Game } from "../Game";
import { GameScore } from "../GameScore";
import { MultiPlayerDashboard } from "../MultiPlayerDashboard";
import { Openings } from "../Openings";
import { Settings } from "../Settings";
import { SetUsername } from "../SetUsername";
import { StartMultiPlayerGame } from "../StartMultiPlayerGame";
import { Statistics } from "../Statistics";

const Route = ({
  component: Component,
  ...props
}: {
  component: React.FC<RouteComponentProps>;
} & RouteComponentProps) => <Component {...props} />;

export const App: React.FC = () => {
  const [user] = useContext(AuthContext);
  if (user.isLoading) {
    return <Loading />;
  }
  if (!user.doc) {
    return <Authentication />;
  }
  if (!user.doc!.displayName) {
    navigate(RouteType.FIRST_LOGIN);
  }
  return (
    <UserContextProvider>
      <OpeningsContextProvider>
        <MultiPlayerGamesContextProvider>
          <Header />
          <Router component="main" style={{ padding: theme.spacings._16 }}>
            <Route path={RouteType.DASHBOARD} component={Dashboard} />
            <Route path={RouteType.DELETE_ACCOUNT} component={DeleteAccount} />
            <Route path={RouteType.FIRST_LOGIN} component={SetUsername} />
            <Route path={RouteType.GAME} component={Game} />
            <Route path={`${RouteType.GAME}/:gameId`} component={Game} />
            <Route path={RouteType.GAME_SCORE} component={GameScore} />
            <Route
              path={`${RouteType.GAME_SCORE}/:gameId`}
              component={GameScore}
            />
            <Route
              path={RouteType.MULTI_PLAYER}
              component={MultiPlayerDashboard}
            />
            <Route path={RouteType.OPENINGS} component={Openings} />
            <Route path={RouteType.SET_USERNAME} component={SetUsername} />
            <Route path={RouteType.SETTINGS} component={Settings} />
            <Route
              path={`${RouteType.START_GAME}/:uid`}
              component={StartMultiPlayerGame}
            />
            <Route path={RouteType.STATISTICS} component={Statistics} />
            <Redirect from="/" to={RouteType.DASHBOARD} default noThrow />
          </Router>
        </MultiPlayerGamesContextProvider>
      </OpeningsContextProvider>
    </UserContextProvider>
  );
};

import { Card } from "../types";
import { getHigherCard } from "./getHigherCard";
import { getLowerCard } from "./getLowerCard";

export const isCardLegalInLastRound = (
  card: Card,
  cardsPlayedInLastRound: Card[],
) => {
  if (cardsPlayedInLastRound.length === 0) {
    return card.value === "U" && card.color === "herz";
  }
  if (card.value === "U") return true;
  const higherCard = getHigherCard(card);
  if (
    higherCard &&
    cardsPlayedInLastRound.find(
      ({ color, value }) =>
        color === higherCard.color && value === higherCard.value,
    )
  ) {
    return true;
  }
  const lowerCard = getLowerCard(card);
  if (
    lowerCard &&
    cardsPlayedInLastRound.find(
      ({ color, value }) =>
        color === lowerCard.color && value === lowerCard.value,
    )
  ) {
    return true;
  }
  return false;
};

import React from "react";

import { Card } from "../../types";
import { Eichel } from "./Eichel";
import { Gras } from "./Gras";
import { Herz } from "./Herz";
import { Schellen } from "./Schellen";

export const mapCardColorToComponent = (color: Card["color"]) => {
  switch (color) {
    case "eichel":
      const TransformedEichel = () => (
        <g transform="scale(0.4444) translate(30 20)">
          <Eichel />
        </g>
      );
      return TransformedEichel;
    case "gras":
      const TransformedGras = () => (
        <g transform="scale(0.6202) translate(12 20)">
          <Gras />
        </g>
      );
      return TransformedGras;
    case "herz":
      const TransformedHerz = () => (
        <g transform="scale(0.625) translate(12 20)">
          <Herz />
        </g>
      );
      return TransformedHerz;
    case "schellen":
      const TransformedSchellen = () => (
        <g transform="scale(0.6202) translate(12 20)">
          <Schellen />
        </g>
      );
      return TransformedSchellen;
    default:
      return () => null;
  }
};

import { Card } from "../types";

export const getHigherCard = (card: Card) => {
  let higherCard: Card | null;
  switch (card.value) {
    case "7":
      higherCard = { color: card.color, value: "8" };
      break;
    case "8":
      higherCard = { color: card.color, value: "9" };
      break;
    case "9":
      higherCard = { color: card.color, value: "10" };
      break;
    case "10":
      higherCard = { color: card.color, value: "U" };
      break;
    case "U":
      higherCard = { color: card.color, value: "O" };
      break;
    case "O":
      higherCard = { color: card.color, value: "K" };
      break;
    case "K":
      higherCard = { color: card.color, value: "A" };
      break;
    default:
      higherCard = null;
      break;
  }
  return higherCard;
};

import React from "react";

import styled from "../../styled-components";
import { Eichel } from "../Card/Eichel";
import { Gras } from "../Card/Gras";
import { Herz } from "../Card/Herz";
import { Schellen } from "../Card/Schellen";

const StyledColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${props => props.theme.spacings._128};
`;

const StyledRow = styled.div`
  display: flex;
`;

const StyledSvg = styled.svg`
  height: 100px;
  width: 100px;
`;

export const Loading: React.FC = () => (
  <StyledColumn>
    <StyledRow>
      <StyledSvg>
        <g transform="scale(0.81) translate(30 0)">
          <Eichel showPulse />
        </g>
      </StyledSvg>
      <StyledSvg>
        <g transform="scale(1.0938) translate(12 8)">
          <Gras showPulse />
        </g>
      </StyledSvg>
    </StyledRow>
    <StyledRow>
      <StyledSvg>
        <g transform="scale(1.0938) translate(12 8)">
          <Schellen showPulse />
        </g>
      </StyledSvg>
      <StyledSvg>
        <g transform="scale(1.0938) translate(12 12)">
          <Herz showPulse />
        </g>
      </StyledSvg>
    </StyledRow>
  </StyledColumn>
);

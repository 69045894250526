import React, { useContext, useRef } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Input } from "../../components/Input";
import { Stack } from "../../components/Stack";
import { StyledErrorMessage } from "../../components/StyledErrorMessage";
import { StyledHeading } from "../../components/StyledHeading";
import { StyledLink } from "../../components/StyledLink";
import { AuthContext, UserContext } from "../../context";
import { useForm } from "../../hooks";
import { Route } from "../../types";
import { mapErrorToMessage } from "../../utils";

export const DeleteAccount: React.FC = () => {
  const [firebaseUser] = useContext(AuthContext);
  const [user] = useContext(UserContext);
  const form = useRef<HTMLFormElement>(null);
  const { error, isSubmitting, submit } = useForm(
    form,
    ["email"],
    async (formValues, setError) => {
      if (!firebaseUser.doc || !user.ref) return;
      if (firebaseUser.doc.email !== formValues.email) {
        return setError("Das ist nicht deine Email");
      }
      setError(null);
      try {
        await firebaseUser.doc.delete();
        await user.ref.delete();
      } catch (error) {
        setError(mapErrorToMessage(error));
      }
    },
  );
  return (
    <Container>
      <StyledLink to={Route.SETTINGS}>zurück</StyledLink>
      <Divider />
      <StyledHeading>Account löschen</StyledHeading>
      <p>
        Wenn du deinen Account wirklich löschen möchtest, dann gib zur
        Bestätigung deine Email-Adresse ein:
      </p>
      <form onSubmit={submit} ref={form}>
        <Stack scale="_12">
          <label htmlFor="email">Email:</label>
          <Input id="email" type="email" />
          <ButtonWrapper>
            <Button isLoading={isSubmitting} priority="primary" type="submit">
              Account löschen
            </Button>
          </ButtonWrapper>
          {error && <StyledErrorMessage>{error}</StyledErrorMessage>}
        </Stack>
      </form>
    </Container>
  );
};

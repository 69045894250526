import React from "react";

import { theme } from "../../theme";

type HerzProps = {
  showPulse?: boolean;
};

export const Herz: React.FC<HerzProps> = ({ showPulse }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" width="66" height="64">
    <g style={{ transformOrigin: "center" }} stroke={theme.colors.black}>
      {showPulse && (
        <>
          <animateTransform
            id="svg-herz-pulse-1"
            attributeName="transform"
            type="scale"
            from="1"
            to="0.8"
            dur="0.25s"
            begin="0.5s;svg-herz-pulse-1.end+0.75s"
          />
          <animateTransform
            id="svg-herz-pulse-2"
            attributeName="transform"
            type="scale"
            from="0.8"
            to="1"
            dur="0.25s"
            begin="0.75s;svg-herz-pulse-2.end+0.75s"
          />
        </>
      )}
      <path
        fill={theme.colors.red[400]}
        d="M33 64q-9-14-21-24Q1 30.5 1 17 1 1 18 1q8 0 15 7 7-7 15-7 17 0 17 16 0 13.5-11 23-12 10-21 24"
      />
      <path
        fill="none"
        d="M33 8v56m2-57.8V61m2-56.3V58m2-54.3v52m2-52.9v50.5M43 2v49m2-49.5v47.1M47 1v45.4M49 1v43.3M51 1v41.4m2-41.1v39.2m2-38.8v37.1m2-36.3V37m2-33.3v31m2-29.5v27.1M63 8v20"
      />
    </g>
  </svg>
);

import { navigate } from "@reach/router";
import React, { memo, useContext } from "react";

import { Divider } from "../../components/Divider";
import { StyledLink } from "../../components/StyledLink";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import { AuthContext } from "../../context";
import styled from "../../styled-components";
import { Game, Route } from "../../types";
import { finishGame } from "../../utils";

const StyledLinks = styled.div`
  grid-column: 1 / 25;
  grid-row: 1 / 2;
  display: flex;
  justify-content: space-between;
`;

const StyledDivider = styled(Divider)`
  grid-column: 1 / 25;
  grid-row: 2 / 3;
  margin-top: ${props => props.theme.spacings._16};
`;

type GameLinksProps = {
  game: Game;
  gameId: string | undefined;
};

export const GameLinks: React.FC<GameLinksProps> = memo(({ game, gameId }) => {
  const [user] = useContext(AuthContext);
  const pathname = gameId
    ? window.location.pathname.replace(`/${gameId}`, "")
    : window.location.pathname;
  return (
    <>
      <StyledLinks>
        <StyledLink to={gameId ? Route.MULTI_PLAYER : Route.DASHBOARD}>
          {game.round > 6 ? "" : "zurück"}
        </StyledLink>
        {game.round > 6 ? (
          <StyledLinkButton
            onClick={() => {
              if (user.doc && user.doc.uid === game.createdBy) {
                finishGame(game, gameId);
              }
              navigate(Route.DASHBOARD);
            }}
          >
            Spiel{" "}
            {!gameId || (user.doc && user.doc.uid === game.createdBy)
              ? "beenden"
              : "verlassen"}
          </StyledLinkButton>
        ) : (
          <StyledLink
            to={`${pathname === Route.GAME ? Route.GAME_SCORE : Route.GAME}${
              gameId ? `/${gameId}` : ""
            }`}
          >
            {pathname === Route.GAME ? "Spielstand anzeigen" : "weiterspielen"}
          </StyledLink>
        )}
      </StyledLinks>
      <StyledDivider />
    </>
  );
});

GameLinks.displayName = "memo(GameLinks)";

import { navigate, RouteComponentProps } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useRef, useState } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Loading } from "../../components/Loading";
import { Stack } from "../../components/Stack";
import {
  StyledHeading,
  StyledSubHeading,
} from "../../components/StyledHeading";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import {
  AuthContext,
  MultiPlayerGamesContext,
  OpeningsContext,
  UserContext,
} from "../../context";
import { useRedirectOnNetworkStatusChange } from "../../hooks";
import { NetworkStatus, Opening, Route } from "../../types";
import { createGame } from "../../utils";

export const StartMultiPlayerGame: React.FC<
  RouteComponentProps<{ uid: string }>
> = ({ uid }) => {
  useRedirectOnNetworkStatusChange(NetworkStatus.OFFLINE, Route.MULTI_PLAYER);
  const [firebaseUser] = useContext(AuthContext);
  const { games } = useContext(MultiPlayerGamesContext);
  const { isLoading, openings } = useContext(OpeningsContext);
  const [user] = useContext(UserContext);
  const [isStartingGame, setIsStartingGame] = useState(false);
  const [isDeletingOpening, setIsDeletingOpening] = useState(false);
  const previousOpening = useRef<Opening | null>(null);
  if (isLoading || !openings || firebaseUser.isLoading || !firebaseUser.doc) {
    return <Loading />;
  }
  const currentUid = firebaseUser.doc ? firebaseUser.doc.uid : null;
  const isCreator = currentUid && currentUid === uid;
  const opening = openings.find(opening => opening.uid === uid);
  if (!opening) {
    const firstGame = Object.entries(games).find(([, game]) =>
      Boolean(
        game.createdBy === uid &&
          currentUid &&
          game.authorizedUsers.includes(currentUid),
      ),
    );
    if (firstGame) {
      const [gameId] = firstGame;
      navigate(`${Route.GAME}/${gameId}`);
    }
    return (
      <Container>
        <p>
          Diese Spieleröffnung existiert nicht.
          {previousOpening.current
            ? " Möglicherweise wurde sie gerade vom Spielleiter gelöscht."
            : ""}
        </p>
        <ButtonWrapper>
          <Button
            onClick={() => {
              navigate(Route.MULTI_PLAYER);
            }}
            priority="primary"
          >
            zur Übersicht
          </Button>
        </ButtonWrapper>
      </Container>
    );
  }
  previousOpening.current = opening;
  const deleteOpening = (id: string) =>
    firebase
      .firestore()
      .collection("openings")
      .doc(id)
      .delete();
  return (
    <Container>
      <StyledLinkButton
        onClick={() => {
          window.history.back();
        }}
      >
        zurück
      </StyledLinkButton>
      <Divider />
      <Stack scale="_32">
        <StyledHeading>Neues Spiel beginnen</StyledHeading>
        <Stack>
          <ul>
            <li>
              Spielersteller: <strong>{opening.name}</strong>
            </li>
            {opening.players.map(player => (
              <li key={player.uid}>
                Mitspieler: <strong>{player.name}</strong>
              </li>
            ))}
          </ul>
          <p>Noch {3 - opening.players.length} freie Plätze.</p>
        </Stack>
        <Divider />
        <Stack>
          <StyledSubHeading>mögliche Mitspieler</StyledSubHeading>
          {opening.applicants.length === 0 && (
            <p>Im Moment gibt es keine Spieler, die mitspielen wollen.</p>
          )}
          {opening.applicants.map(applicant => (
            <Fragment key={applicant.uid}>
              <p>
                <strong>{applicant.name}</strong> will mitspielen
              </p>
              {isCreator && opening.players.length < 3 && (
                <ButtonWrapper>
                  <Button
                    onClick={() => {
                      if (!currentUid) return;
                      firebase
                        .firestore()
                        .collection("openings")
                        .doc(currentUid)
                        .update({
                          applicants: opening.applicants.filter(
                            a => a.uid !== applicant.uid,
                          ),
                          players: [...opening.players, applicant],
                        });
                    }}
                    priority="primary"
                  >
                    hinzufügen
                  </Button>
                  <Button
                    onClick={() => {
                      if (!currentUid) return;
                      firebase
                        .firestore()
                        .collection("openings")
                        .doc(currentUid)
                        .update({
                          applicants: opening.applicants.filter(
                            a => a.uid !== applicant.uid,
                          ),
                        });
                    }}
                  >
                    ablehnen
                  </Button>
                </ButtonWrapper>
              )}
            </Fragment>
          ))}
        </Stack>
        {isCreator && (
          <>
            <Divider />
            <ButtonWrapper>
              <Button
                isLoading={isStartingGame}
                priority="primary"
                onClick={async () => {
                  if (!firebaseUser.doc || !user.data) return;
                  setIsStartingGame(true);
                  const game = await firebase
                    .firestore()
                    .collection("games")
                    .add(
                      createGame(
                        {
                          name: firebaseUser.doc.displayName || "",
                          uid: firebaseUser.doc.uid,
                        },
                        opening.players,
                        user.data.settings,
                      ),
                    );
                  deleteOpening(firebaseUser.doc.uid);
                  navigate(`${Route.GAME}/${game.id}`);
                }}
              >
                Spiel starten
              </Button>
              <Button
                isLoading={isDeletingOpening}
                onClick={async () => {
                  if (!currentUid) return;
                  setIsDeletingOpening(true);
                  deleteOpening(currentUid);
                  navigate(Route.MULTI_PLAYER);
                }}
              >
                Spieleröffnung beenden
              </Button>
            </ButtonWrapper>
          </>
        )}
      </Stack>
    </Container>
  );
};

import React from "react";

import styled from "../../styled-components";
import { Eichel } from "../Card/Eichel";
import { Gras } from "../Card/Gras";
import { Herz } from "../Card/Herz";
import { Schellen } from "../Card/Schellen";

type ButtonProps = StyledButtonProps & {
  disabled?: React.HTMLProps<HTMLButtonElement>["disabled"];
  isLoading?: boolean;
  onClick?: React.HTMLProps<HTMLButtonElement>["onClick"];
  onMouseUp?: React.HTMLProps<HTMLButtonElement>["onMouseUp"];
  type?: React.HTMLProps<HTMLButtonElement>["type"];
};

type StyledButtonProps = {
  priority?: "primary" | "secondary";
};

const StyledButton = styled.button<StyledButtonProps>`
  background-color: ${props =>
    props.priority === "primary"
      ? props.theme.colors.black
      : props.theme.colors.gray["800"]};
  color: ${props =>
    props.theme.colors[props.priority === "primary" ? "white" : "black"]};
  border: ${props => props.theme.borders._2} solid
    ${props => props.theme.colors.black};
  padding: ${props =>
    `${props.theme.spacings._12} ${props.theme.spacings._16}`};
  cursor: pointer;

  &:focus,
  &:active {
    box-shadow: 0 0 0
      ${props => `${props.theme.borders._4} ${props.theme.colors.gray[500]}`};
  }
`;

const StyledSvg = styled.svg`
  height: 16px;
  width: 16px;
`;

export const Button: React.FC<ButtonProps> = ({
  children,
  isLoading,
  ...props
}) => (
  <StyledButton type="button" {...props}>
    {isLoading ? (
      <>
        <StyledSvg>
          <g transform="scale(0.1336) translate(28 0)">
            <Eichel showPulse />
          </g>
        </StyledSvg>
        <StyledSvg>
          <g transform="scale(0.18) translate(12 8)">
            <Gras showPulse />
          </g>
        </StyledSvg>
        <StyledSvg>
          <g transform="scale(0.173) translate(12 12)">
            <Herz showPulse />
          </g>
        </StyledSvg>
        <StyledSvg>
          <g transform="scale(0.175) translate(12 8)">
            <Schellen showPulse />
          </g>
        </StyledSvg>
      </>
    ) : (
      children
    )}
  </StyledButton>
);

Button.displayName = "Button";

export const ButtonWrapper = styled.div`
  > * {
    width: 100%;
  }
  > * + * {
    border-top-width: 0;
  }
  @media (min-width: ${props => props.theme.breakpoints._452}) {
    > * {
      width: auto;
    }
    > * + * {
      border-top-width: ${props => props.theme.borders._2};
      border-left-width: 0;
    }
  }
`;

import { Card, Game } from "../types";
import { getActivePlayerIndexInLastRound } from "./";

export const playCard = (game: Game, card: Card, isFirstCard: boolean) => {
  const numberOfPlayedCards = game.players.filter(player => player.cardPlayed)
    .length;
  const gameWithPlayedCard: Game = {
    ...game,
    activePlayerIndex:
      game.round === 6
        ? getActivePlayerIndexInLastRound(game)
        : numberOfPlayedCards >= 3
        ? game.activePlayerIndex
        : (game.activePlayerIndex + 1) % 4,
    players: game.players.map((p, index) => {
      if (game.activePlayerIndex === index) {
        const updatedPlayer = {
          ...p,
          cardPlayed: game.round === 6 ? null : card,
          hand: p.hand.filter(
            ({ color, value }) =>
              !(color === card.color && value === card.value),
          ),
        };
        if (game.round === 6 && updatedPlayer.hand.length === 0) {
          updatedPlayer.finishedInPlace = game.players.filter(
            ({ finishedInPlace }) => finishedInPlace !== null,
          ).length;
        }
        return updatedPlayer;
      }
      return p;
    }),
  };
  if (isFirstCard) {
    gameWithPlayedCard.firstCardPlayed = card;
  }
  if (game.round === 6) {
    gameWithPlayedCard.cardsPlayedInLastRound = [
      ...game.cardsPlayedInLastRound,
      card,
    ];
  }
  return gameWithPlayedCard;
};

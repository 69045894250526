import React from "react";

import { theme } from "../../theme";

type GrasProps = {
  showPulse?: boolean;
};

export const Gras: React.FC<GrasProps> = ({ showPulse }) => (
  <svg xmlns="http://www.w3.org/2000/svg" version="1" width="66" height="73">
    <g style={{ transformOrigin: "center" }}>
      {showPulse && (
        <>
          <animateTransform
            id="svg-gras-pulse-1"
            attributeName="transform"
            type="scale"
            from="1"
            to="0.8"
            dur="0.25s"
            begin="0.25s;svg-gras-pulse-1.end+0.75s"
          />
          <animateTransform
            id="svg-gras-pulse-2"
            attributeName="transform"
            type="scale"
            from="0.8"
            to="1"
            dur="0.25s"
            begin="0.5s;svg-gras-pulse-2.end+0.75s"
          />
        </>
      )}
      <path
        fill={theme.colors.green}
        stroke={theme.colors.black}
        d="M35 54q-1 10-7 14-3 1-4 4-2-3 1-6 8-6 5-12"
      />
      <path
        fill="none"
        stroke={theme.colors.black}
        d="M33.5 54q-1 10-6 12.5M25 66c1-1 3-1 3 2M25 70.4c-.3-3.4 2-3.4 1-2.4"
      />
      <path
        fill={theme.colors.green}
        stroke={theme.colors.black}
        d="M33 1q-9 13-21 23Q1 33.5 1 47q0 16 17 16 8 0 15-7 7 7 15 7 17 0 17-16 0-13.5-11-23Q42 14 33 1"
      />
      <path
        fill="none"
        stroke={theme.colors.black}
        d="M33 1v55m2-52v53.8m2-51.1v52.6M39 9v51.8m2-49.5v50.2m2-47.8V62m2-46v46.5M47 18v45m2-43.3V63m2-41.5V63m2-39.5v39.2m2-37.5v37M57 27v34.5m2-32.2v31m2-28v26.5M63 37v18.5M28 18q10 7 18 3m-8-3q-10 7-18 3m7 1.5q-4 0-8 2.5m20-2.5q4 0 8 2.5"
      />
      <path
        transform="matrix(1.7 0 0 1 -23 12)"
        fill="none"
        stroke={theme.colors.black}
        d="M28 18q10 7 18 3m-8-3q-10 7-18 3m7 1.5q-4 0-8 2.5m20-2.5q4 0 8 2.5"
      />
      <path
        transform="matrix(2 0 0 1 -33 24)"
        fill="none"
        stroke={theme.colors.black}
        d="M28 18q10 7 18 3m-8-3q-10 7-18 3m7 1.5q-4 0-8 2.5m20-2.5q4 0 8 2.5"
      />
    </g>
  </svg>
);

export const mapErrorToMessage = (error: any) => {
  switch (error.code) {
    case "auth/invalid-email":
      return "Die Email ist ungültig.";
    case "auth/weak-password":
      return "Das Password muss länger als 6 Zeichen sein.";
    case "auth/email-already-in-use":
      return "Für deine Email gibt es schon einen Nutzer.";
    case "auth/user-not-found":
    case "auth/wrong-password":
      return "Die Email oder das Passwort stimmt nicht.";
    case "auth/network-request-failed":
      return "Der Server ist nicht erreichbar. Bist du offline?";
    default:
      return error.message;
  }
};

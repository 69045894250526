import { Game } from "../types";
import { getScoreForPlayer } from "./getScoreForPlayer";
import { shuffleCards } from "./shuffleCards";

export const nextRound = (game: Game) => {
  const shuffledCards = shuffleCards();
  const gameWithCalcualtedScores: Game = {
    ...game,
    activePlayerIndex: (game.initialPlayerIndex + 1) % 4,
    firstCardPlayed: null,
    initialPlayerIndex: (game.initialPlayerIndex + 1) % 4,
    players: game.players.map((player, index) => ({
      ...player,
      cardPlayed: null,
      hand: shuffledCards.slice(index * 8, (index + 1) * 8),
      indicesOfTricks: [],
      scorePerRound: [
        ...player.scorePerRound,
        (player.scorePerRound.length === 0
          ? 0
          : player.scorePerRound[player.scorePerRound.length - 1]) +
          getScoreForPlayer(
            player.tricks,
            player.indicesOfTricks,
            player.finishedInPlace || 0,
            game.round,
          ),
      ],
      tricks: [],
    })),
    round: game.round + 1,
  };
  return gameWithCalcualtedScores;
};

import React, { memo } from "react";

import { Card } from "../../components/Card";
import styled from "../../styled-components";
import {
  getRowForSidePlayerCard,
  PlayerCardsProps,
  StyledCardProps,
} from "./shared";

const StyledRightPlayerName = styled.span`
  grid-column: 22 / 25;
  grid-row: 7 / 8;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  margin-bottom: ${props => props.theme.spacings._16};
`;

export const RightPlayerName = memo(StyledRightPlayerName);

const StyledRightPlayerCard = styled(Card)<StyledCardProps>`
  height: 28px;
  width: 50px;
  grid-column: 23 / 25;
  grid-row: ${({ index, numberOfCards }) =>
    getRowForSidePlayerCard({ index, numberOfCards })};

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    height: 56px;
    width: 100px;
  }
`;

export const RightPlayerCards: React.FC<PlayerCardsProps> = memo(({ hand }) => (
  <>
    {hand.map((card, cardIndex) => (
      <StyledRightPlayerCard
        key={`rightPlayerCard${cardIndex}`}
        index={cardIndex}
        numberOfCards={hand.length}
        isFacedDown
        isHorizontal
        {...card}
      />
    ))}
  </>
));

RightPlayerCards.displayName = "memo(RightPlayerCards)";

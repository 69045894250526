import { navigate } from "@reach/router";
import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { Fragment, useContext, useState } from "react";

import { Button, ButtonWrapper } from "../../components/Button";
import { Container } from "../../components/Container";
import { Divider } from "../../components/Divider";
import { Loading } from "../../components/Loading";
import { Stack } from "../../components/Stack";
import {
  StyledHeading,
  StyledSubHeading,
} from "../../components/StyledHeading";
import { StyledLink } from "../../components/StyledLink";
import { StyledLinkButton } from "../../components/StyledLinkButton";
import {
  AuthContext,
  MultiPlayerGamesContext,
  NetworkContext,
  OpeningsContext,
} from "../../context";
import { Route } from "../../types";
import { createOpening } from "../../utils";

export const MultiPlayerDashboard: React.FC = () => {
  const [user] = useContext(AuthContext);
  const networkStatus = useContext(NetworkContext);
  const { isLoading: areOpeningsLoading, openings } = useContext(
    OpeningsContext,
  );
  const { isLoading: areGamesLoading, games } = useContext(
    MultiPlayerGamesContext,
  );
  const [isOpeningGame, setIsOpeningGame] = useState(false);
  if (areOpeningsLoading || areGamesLoading || !user.doc) {
    return <Loading />;
  }
  const { displayName, uid } = user.doc;
  const openingForCurrentUser = openings.find(opening => opening.uid === uid);
  const openingsWhereUserIsPlayer = openings.filter(opening =>
    opening.players.find(player => player.uid === uid),
  );
  const openingsWhereUserHasApplied = openings.filter(opening =>
    opening.applicants.find(player => player.uid === uid),
  );
  const filteredOpenings = openings.filter(
    opening =>
      opening.uid !== uid &&
      !opening.players.find(player => player.uid === uid) &&
      !opening.applicants.find(applicant => applicant.uid === uid),
  );
  const canHaveMoreGames = Object.keys(games).length <= 2;

  return (
    <Container>
      <StyledLinkButton
        onClick={() => {
          window.history.back();
        }}
      >
        zurück
      </StyledLinkButton>
      <Divider />
      <StyledHeading>Zusammen spielen</StyledHeading>
      {networkStatus === "online" ? (
        <>
          <Stack scale="_32">
            <Stack scale="_16">
              <StyledSubHeading>Aktive Spiele</StyledSubHeading>
              {Object.keys(games).length === 0 && (
                <p>Du bist gerade an keinen aktiven Spielen beteiligt.</p>
              )}
              {Object.entries(games).map(([gameId, game], index) => {
                const creator = game.players.find(
                  player => player.uid === game.createdBy,
                );
                return (
                  <Fragment key={gameId}>
                    {index > 0 && <Divider />}
                    <p>Gestartet von {creator ? creator.name : ""}</p>
                    <ButtonWrapper>
                      <Button
                        onClick={() => {
                          navigate(`${Route.GAME}/${gameId}`);
                        }}
                        priority="primary"
                      >
                        weiterspielen
                      </Button>
                      <Button
                        onClick={() => {
                          if (game.createdBy === uid) {
                            firebase
                              .firestore()
                              .collection("games")
                              .doc(gameId)
                              .delete();
                            return;
                          }
                          firebase
                            .firestore()
                            .collection("games")
                            .doc(gameId)
                            .update({
                              authorizedUsers: game.authorizedUsers.filter(
                                id => id !== uid,
                              ),
                              players: game.players.map(player => {
                                if (player.uid === uid) {
                                  return { ...player, uid: null };
                                }
                                return player;
                              }),
                            });
                        }}
                      >
                        Spiel {game.createdBy === uid ? "löschen" : "verlassen"}
                      </Button>
                    </ButtonWrapper>
                  </Fragment>
                );
              })}
            </Stack>
            <Divider />
            <Stack scale="_16">
              <StyledSubHeading>Neues Spiel beginnen</StyledSubHeading>
              {!canHaveMoreGames && (
                <p>
                  Du bist bereits an drei aktiven Spielen beteiligt. Mehr aktive
                  Spiele sind nicht erlaubt. Beende eines der Spiele um ein
                  neues zu beginnen.
                </p>
              )}
              {canHaveMoreGames && openingForCurrentUser && (
                <>
                  <p>
                    Du versuchst bereits ein neues Spiel zu eröffnen. Sieh nach,
                    ob du schon Mitspieler gefunden hast!
                  </p>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        navigate(`${Route.START_GAME}/${uid}`);
                      }}
                      priority="primary"
                    >
                      zur Spieleröffnung
                    </Button>
                  </ButtonWrapper>
                </>
              )}
              {canHaveMoreGames && !openingForCurrentUser && (
                <>
                  <p>Du kannst selbst ein Spiel eröffnen.</p>
                  <ButtonWrapper>
                    <Button
                      isLoading={isOpeningGame}
                      onClick={async () => {
                        setIsOpeningGame(true);
                        await firebase
                          .firestore()
                          .collection("openings")
                          .doc(uid)
                          .set(createOpening(uid, displayName || ""));
                        navigate(`${Route.START_GAME}/${uid}`);
                      }}
                      priority="primary"
                    >
                      neues Spiel eröffnen
                    </Button>
                  </ButtonWrapper>
                </>
              )}
              {canHaveMoreGames && filteredOpenings.length > 0 && (
                <>
                  <p>
                    Sieh dir hier alle von anderen Spielern eröffneten Spiele
                    an:
                  </p>
                  <ButtonWrapper>
                    <Button
                      onClick={() => {
                        navigate(Route.OPENINGS);
                      }}
                    >
                      offenen Spiele ansehen
                    </Button>
                  </ButtonWrapper>
                  <p>
                    Aktuell {filteredOpenings.length === 1 ? "ist" : "sind"}{" "}
                    {filteredOpenings.length > 10
                      ? "mehr als 10"
                      : filteredOpenings.length}{" "}
                    Spiel
                    {filteredOpenings.length === 1 ? "" : "e"} offen.
                  </p>
                </>
              )}
            </Stack>
            <Divider />
            <Stack scale="_16">
              <StyledSubHeading>Spiel beitreten</StyledSubHeading>
              {openingsWhereUserIsPlayer.length +
                openingsWhereUserHasApplied.length ===
                0 && <p>Du bist keinem eröffneten Spiel beigetreten.</p>}
              {openingsWhereUserIsPlayer.length > 0 && (
                <>
                  <p>
                    Du wurdest in folgenden Spielen bereits als Spieler
                    hinzugefügt.
                  </p>
                  <ul>
                    {openingsWhereUserIsPlayer.map(opening => (
                      <li key={opening.uid}>
                        <StyledLink to={`${Route.START_GAME}/${opening.uid}`}>
                          Erstellt von <strong>{opening.name}</strong>
                        </StyledLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
              {openingsWhereUserHasApplied.length > 0 && (
                <>
                  <p>In folgenden Spielen möchtest du mitspielen.</p>
                  <ul>
                    {openingsWhereUserHasApplied.map(opening => (
                      <li key={opening.uid}>
                        <StyledLink to={`${Route.START_GAME}/${opening.uid}`}>
                          Erstellt von <strong>{opening.name}</strong>
                        </StyledLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </Stack>
          </Stack>
        </>
      ) : (
        <p>Um mit anderen zu spielen musst du online sein.</p>
      )}
    </Container>
  );
};

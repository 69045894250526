import React, { memo } from "react";

import { Card } from "../../components/Card";
import styled from "../../styled-components";
import {
  getRowForSidePlayerCard,
  PlayerCardsProps,
  StyledCardProps,
} from "./shared";

const StyledLeftPlayerName = styled.span`
  grid-column: 1 / 4;
  grid-row: 7 / 8;
  display: flex;
  align-items: flex-end;
  margin-bottom: ${props => props.theme.spacings._16};
`;

export const LeftPlayerName = memo(StyledLeftPlayerName);

const StyledLeftPlayerCard = styled(Card)<StyledCardProps>`
  height: 28px;
  width: 50px;
  grid-column: 1 / 3;
  grid-row: ${({ index, numberOfCards }) =>
    getRowForSidePlayerCard({ index, numberOfCards })};

  @media (min-width: ${props =>
      props.theme.breakpoints._452}) and (min-height: ${props =>
      props.theme.breakpoints._860}) {
    height: 56px;
    width: 100px;
  }
`;

export const LeftPlayerCards: React.FC<PlayerCardsProps> = memo(
  ({ hand }) => (
    <>
      {hand.map((card, cardIndex) => (
        <StyledLeftPlayerCard
          key={`leftPlayerCard${cardIndex}`}
          index={cardIndex}
          numberOfCards={hand.length}
          isFacedDown
          isHorizontal
          {...card}
        />
      ))}
    </>
  ),
  (prevProps, props) => prevProps.hand.length === props.hand.length,
);

LeftPlayerCards.displayName = "memo(LeftPlayerCards)";

import { Card } from "../types";

export const cardList: Card[] = [
  { color: "eichel", value: "7" },
  { color: "eichel", value: "8" },
  { color: "eichel", value: "9" },
  { color: "eichel", value: "10" },
  { color: "eichel", value: "U" },
  { color: "eichel", value: "O" },
  { color: "eichel", value: "K" },
  { color: "eichel", value: "A" },
  { color: "gras", value: "7" },
  { color: "gras", value: "8" },
  { color: "gras", value: "9" },
  { color: "gras", value: "10" },
  { color: "gras", value: "U" },
  { color: "gras", value: "O" },
  { color: "gras", value: "K" },
  { color: "gras", value: "A" },
  { color: "herz", value: "7" },
  { color: "herz", value: "8" },
  { color: "herz", value: "9" },
  { color: "herz", value: "10" },
  { color: "herz", value: "U" },
  { color: "herz", value: "O" },
  { color: "herz", value: "K" },
  { color: "herz", value: "A" },
  { color: "schellen", value: "7" },
  { color: "schellen", value: "8" },
  { color: "schellen", value: "9" },
  { color: "schellen", value: "10" },
  { color: "schellen", value: "U" },
  { color: "schellen", value: "O" },
  { color: "schellen", value: "K" },
  { color: "schellen", value: "A" },
];

export const getSortValueForCard = (card: Card) =>
  cardList.findIndex(c => c.color === card.color && c.value === card.value);

export const cardsGroupedByColor = cardList
  .reduce(
    (acc, card) => {
      let accIndex;
      switch (card.color) {
        case "gras":
          accIndex = 1;
          break;
        case "herz":
          accIndex = 2;
          break;
        case "schellen":
          accIndex = 3;
          break;
        default:
          accIndex = 0;
      }
      acc[accIndex].push(card);
      return acc;
    },
    [[], [], [], []] as Card[][],
  )
  .map(cardsOfOneColor =>
    cardsOfOneColor.sort(
      (card1, card2) => getSortValueForCard(card1) - getSortValueForCard(card2),
    ),
  );

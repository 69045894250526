export const nameList = [
  "Hans",
  "Peter",
  "Franz",
  "Max",
  "Thomas",
  "Günther",
  "Johann",
  "Sepp",
  "Hannes",
  "Lukas",
  "Felix",
  "Hanna",
  "Anna",
  "Lisa",
  "Lena",
  "Luisa",
  "Marlene",
  "Vicki",
  "Andrea",
  "Sabine",
  "Susi",
  "Helene",
  "Ruth",
];

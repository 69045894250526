import * as firebase from "firebase/app";
import "firebase/firestore";
import React, { createContext, useContext, useEffect, useState } from "react";

import { AuthContext } from "./AuthContext";
import { Game } from "../types";

type State = { isLoading: boolean; games: { [key: string]: Game } };

const initialState: State = { isLoading: true, games: {} };

export const MultiPlayerGamesContext = createContext(initialState);

export const MultiPlayerGamesContextProvider: React.FC = ({ children }) => {
  const [games, setGames] = useState(initialState);
  const [user] = useContext(AuthContext);
  const uid = user.doc ? user.doc.uid : null;
  useEffect(() => {
    firebase
      .firestore()
      .collection("games")
      .where("authorizedUsers", "array-contains", uid)
      .onSnapshot(querySnapshot => {
        const activeGames: { [key: string]: Game } = {};
        querySnapshot.forEach(
          doc => (activeGames[doc.id] = doc.data() as Game),
        );
        setGames({ isLoading: false, games: activeGames });
      });
  }, [uid]);
  return (
    <MultiPlayerGamesContext.Provider value={games}>
      {children}
    </MultiPlayerGamesContext.Provider>
  );
};
